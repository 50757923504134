import { Outlet, useLocation } from 'react-router'
import { useEffect } from 'react'

import { ModelProvider } from '@/context/ModelContext'

import Footer from '../Footer'
import Header from '../Header'
import FirebaseProvider from '@/context/FirebaseProvider'

const LayoutDefault = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const hashEl = document.getElementById(location.hash.replace('#', ''))
        if (hashEl) {
          hashEl.scrollIntoView({ behavior: 'smooth' })
        }
      }, 50)
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 50)
    }
  }, [location])

  return (
    <ModelProvider>
      <Header themeDefault="dark" />
      <FirebaseProvider>
        <Outlet />
        <Footer />
      </FirebaseProvider>
    </ModelProvider>
  )
}

export default LayoutDefault
