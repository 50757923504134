import { Grid } from '@mui/material'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

import formatNumberWithCommas from '@/utils/formatNumberWithComas'
import { useTranslation } from 'react-i18next'

const SectionPotentialStyled = styled.section`
  position: relative;
  padding: 205px 100px;
  background: radial-gradient(
    81.42% 233.58% at 50% 81.42%,
    #2d343e 0%,
    #141c26 100%
  );
  display: flex;
  justify-content: center;

  .line {
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 102.04%
    );
    border-radius: 0 48.5px 50px 0;
  }

  .line-1 {
    top: 23%;
    left: 0;
    width: 526px;
    height: 57px;
  }

  .line-2 {
    top: 30%;
    left: 72%;
    width: 345px;
    height: 60px;
  }

  .line-3 {
    top: 70%;
    right: -5%;
    width: 305px;
    height: 42px;
  }

  .line-4 {
    top: 88%;
    left: 2%;
    width: 466px;
    height: 65px;
  }

  @media (max-width: 1200px) {
    padding: 50px;
  }

  @media (max-width: 992px) {
    padding: 30px;
    .line {
      display: none;
    }
  }
`

const PotentialContentStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 1242px;
  .wrapper {
    max-width: 1242px;
    width: 100%;
    box-sizing: border-box;
    padding: 87px 166px 95px;
    border: 1px solid transparent;
    border-radius: 30px;
    color: white;
    background-clip: padding-box;
    position: relative;
    z-index: 3;
    background-color: rgb(22, 26, 32);
    backdrop-filter: blur(100px);
    overflow: hidden;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 2;
    margin: -1px;
    background: linear-gradient(180deg, #ffffff 0%, #3e4754 99.94%);
    border-radius: 30px;
  }

  h1 {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 10px;

    span {
      color: #ff821c;
    }
  }

  h5 {
    color: #c9ced6;
    margin-bottom: 75px;
    text-align: center;
    font-weight: 400;
  }

  .count-follow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 65px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      padding: 18px 0;
      background-color: #0000003b;
      border-radius: 6px;
      font-size: 26px;
      font-weight: 500;
      color: white;
      outline: none;
      border: none;
      text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  h6 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-weight: 400;
  }

  @media (max-width: 1200px) {
    .wrapper {
      padding: 50px 100px 50px;
    }

    h5 {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 992px) {
    .wrapper {
      padding: 30px;
    }

    h1 {
      font-size: 1.5rem;
    }

    h5 {
      text-align: center;
      margin-bottom: 20px;
    }

    h6 {
      margin: 30px 0;
    }

    .count-follow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0px;
      font-size: 1.125rem;
      line-height: 1.5;
      margin-bottom: 0px;

      .btn {
        width: 200px;
        padding: 18px 0;
        margin: 0;
      }
    }
  }

  @media (max-width: 576px) {
    .count-follow {
      flex-direction: column;
    }
  }
`

const ValueCountStyled = styled(Grid)`
  padding: 42px 0;
  border: 1px solid #c9ced6;
  border-radius: 10px;

  .count-value {
    div {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 4rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 15px;
      span {
        font-size: 1.5rem;
        line-height: 1.5;
        margin-left: 1rem;
      }
    }

    p {
      text-align: center;
      font-size: 1.25rem;
      color: #e8eaed;
    }

    &:first-of-type {
      border-right: 1px solid #c9ced6;
    }

    .count-money {
      max-width: 100%;
      overflow: hidden;
    }
  }

  @media (max-width: 992px) {
    padding: 20px 30px;
    .count-value {
      div {
        font-size: 2rem;
        margin-bottom: 15px;
        align-items: flex-end;
        span {
          font-size: 1rem;
          margin-left: 5px;
          line-height: 1.2;
        }
      }

      p {
        font-size: 1rem;
      }

      &:first-of-type {
        border-right: 1px solid #c9ced6;
        /* border-bottom: 1px solid #c9ced6; */
        /* margin-bottom: 20px; */
      }
    }
  }

  @media (max-width: 576px) {
    .count-value {
      &:first-of-type {
        border-right: none;
        border-bottom: 1px solid #c9ced6;
        margin-bottom: 20px;
      }
    }
  }
`

const SectionPotential: FC = () => {
  const { t } = useTranslation()
  const [followers, setFollowers] = useState<number | ''>(1000)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const followersQuery = Number(e.target.value)
    if (followersQuery > 0) {
      setFollowers(followersQuery)
    } else {
      setFollowers('')
    }
  }

  return (
    <SectionPotentialStyled>
      <PotentialContentStyled>
        <div className="wrapper">
          <h1>
            {t('home.calculateYour')} <span>{t('home.earningPotential')}</span>
          </h1>
          <h5>{t('home.withASteadyFLow')}</h5>
          <div className="count-follow">
            <p>{t('home.ifYouHave')}</p>
            <input
              type="number"
              className="btn"
              min="1"
              step="1"
              value={followers}
              onChange={(e) => handleChange(e)}
            />
            <p>{t('home.followersDay')}</p>
          </div>
          <h6>{t('home.youCanEarnUpto')}</h6>
          <ValueCountStyled container>
            <Grid item xs={12} sm={6} className="count-value">
              <div className="count-money">
                {formatNumberWithCommas(Number(followers || 0) * 0.5 * 7) || 0}
              </div>
              <p>
                <b>USDT</b> {t('home.perWeek')}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} className="count-value">
              <div className="count-money">
                {formatNumberWithCommas(Number(followers || 0) * 0.5 * 30) || 0}
              </div>
              <p>
                <b>USDT</b> {t('home.perMonth')}
              </p>
            </Grid>
          </ValueCountStyled>
        </div>
      </PotentialContentStyled>
      <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
      <div className="line line-4"></div>
    </SectionPotentialStyled>
  )
}

export default SectionPotential
