import { Container } from '@mui/material'
import styled, { keyframes } from 'styled-components'

import imgCycleBig from '@/assets/banner-big-cycle.svg'
import imgCycleSmall from '@/assets/banner-small-cycle.svg'
import { FC, PropsWithChildren } from 'react'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledContainer = styled(Container)`
  padding-top: 70px;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  background: radial-gradient(
    52.26% 52.26% at 50% 58.27%,
    #2d343e 12.32%,
    #141c26 100%
  );
  overflow: hidden;
  color: #ffffff;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .desc {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #10151d 0.23%,
      rgba(15, 23, 36, 0) 100%
    );
    opacity: 0.6;
  }

  .line {
    position: absolute;
    z-index: 1;
  }

  .line-1 {
    width: 30.75em;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border: 2px solid rgba(48, 62, 80, 0.7);
    animation: ${rotate} 30s linear infinite;

    .ball-1 {
      width: 1.375em;
      aspect-ratio: 1/ 1;
      background-color: #2c3747;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 32%;
    }
  }

  .line-2 {
    width: 49.5625em;
    aspect-ratio: 1 / 1;
    animation: ${rotate} 30s linear infinite;
  }

  .line-3 {
    width: 74.375em;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border: 2px solid rgba(38, 49, 65, 0.64);
    animation: ${rotate} 30s linear infinite;

    .ball-2 {
      width: 1.375em;
      aspect-ratio: 1/ 1;
      background-color: #2c3747;
      border-radius: 50%;
      position: absolute;
      top: 25%;
      right: 5%;
    }

    .ball-3 {
      width: 2.75em;
      aspect-ratio: 1/ 1;
      background-color: #202731;
      border: 4px solid #2c3747;
      border-radius: 50%;
      position: absolute;
      bottom: 25%;
      left: 3.5%;
    }
  }

  .line-4 {
    width: 102.1875em;
    aspect-ratio: 1 / 1;
    animation: ${rotate} 50s linear infinite;
  }

  .big-bird {
    position: absolute;
    width: 370px;
    aspect-ratio: 2.2 / 1;
    top: 40%;
    left: 4.5%;
    z-index: 2;
  }

  .small-bird {
    position: absolute;
    width: 288px;
    aspect-ratio: 2.4 / 1;
    bottom: 20%;
    right: 5%;
    z-index: 2;
  }

  @media only screen and (max-width: 1536px) {
    .big-bird {
      max-width: 300px;
      left: 2%;
      top: 35%;
    }

    .small-bird {
      max-width: 250px;
      right: 2%;
      bottom: 35%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .big-bird {
      max-width: 250px;
      left: -2%;
      top: 35%;
    }

    .small-bird {
      max-width: 200px;
      right: 0%;
      bottom: 40%;
    }
  }

  @media only screen and (max-width: 992px) {
    .line {
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .big-bird {
      max-width: 200px;
      left: 1%;
      top: 25%;
    }

    .small-bird {
      max-width: 150px;
      right: 1%;
      top: 40%;
    }
  }

  @media only screen and (max-width: 576px) {
    .line {
      font-size: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .big-bird {
      max-width: 130px;
      max-height: 65px;
      left: -5%;
      top: 15%;
    }

    .small-bird {
      max-width: 98px;
      max-height: 48px;
      right: 0%;
      top: 25%;
    }
  }
`

const StyledDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HeroSection: FC<PropsWithChildren> = (props) => {
  const { children } = props
  return (
    <StyledContainer id="intro" anchor-data="intro">
      <div className="desc"></div>
      <div className="line line-1">
        <div className="ball-1"></div>
      </div>
      <img src={imgCycleSmall} alt="" className="line line-2" />
      <div className="line line-3">
        <div className="ball-2"></div>
        <div className="ball-3"></div>
      </div>
      <img src={imgCycleBig} alt="" className="line line-4" />
      <StyledDesc>{children}</StyledDesc>
    </StyledContainer>
  )
}

export default HeroSection
