import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import HeroSection from '@/components/HeroSection'

const TextWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
`

export const Support = () => {
  const { t } = useTranslation()
  return (
    <HeroSection>
      <TextWrapper>{t('under_contruction')}</TextWrapper>
    </HeroSection>
  )
}

export default Support
