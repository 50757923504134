import {
  createContext,
  useState,
  useEffect,
  FC,
  PropsWithChildren,
  useContext,
} from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, onSnapshot, doc } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDJkUv6NYAFItxwZSfEJF6bv_ldWLtJCno',
  authDomain: 'herond-prod-565a9.firebaseapp.com',
  projectId: 'herond-prod-565a9',
  storageBucket: 'herond-prod-565a9.appspot.com',
  messagingSenderId: '417781698000',
  appId: '1:417781698000:web:f0603f1f8aa0a671d9bdd5',
}
const collectionId = 'stats'
const documentId = 'm0FiN6V9byCwY0QbIZQl'

interface FirebaseContextType {
  isLoading: boolean
  downloadCount: number
}

const defaultValue: FirebaseContextType = {
  isLoading: false,
  downloadCount: 0,
}

export const FirebaseContext = createContext<FirebaseContextType>(defaultValue)

export const useFirebase = () => useContext(FirebaseContext)

export const FirebaseProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [downloadCount, setDownloadCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const app = initializeApp(firebaseConfig)
    const firestore = getFirestore(app)

    const unsub = onSnapshot(
      doc(firestore, collectionId, documentId),
      (querySnapshot) => {
        const data = querySnapshot.data()
        setDownloadCount(data?.download)
        setIsLoading(true)
      },
    )

    return () => {
      unsub()
    }
  }, [])

  return (
    <FirebaseContext.Provider value={{ isLoading, downloadCount }}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseProvider
