import { useCallback } from 'react'

import { useAppSelector, useAppDispatch } from '@/state/hooks'
import { Toast } from '@/constants/types'

import { AppState } from '../index'

import { addToast, removeToast, updateIsShowDrawer } from './reducer'

export function useIsShowDrawer(): boolean {
  const openModal = useAppSelector(
    (state: AppState) => state.application.isShowDrawer,
  )
  return openModal
}

export function useToasts(): Toast[] {
  return useAppSelector((state: AppState) => state.application.toasts)
}

export function useAddToast() {
  const dispatch = useAppDispatch()
  return useCallback(
    (val: Omit<Toast, 'id'>) => dispatch(addToast(val)),
    [dispatch],
  )
}

export function useRemoveToast() {
  const dispatch = useAppDispatch()
  return useCallback((id: string) => dispatch(removeToast({ id })), [dispatch])
}

export function useUpdateIsShowDrawer() {
  const dispatch = useAppDispatch()
  return useCallback(
    (val: boolean) => {
      dispatch(updateIsShowDrawer({ val }))
    },
    [dispatch],
  )
}
