import {
  Pagination,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Checkbox,
} from '@mui/material'

import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import SupportService from '@/services/supportService'
import { useAddToast } from '@/state/application/hooks'
import { RequestSupportArrType } from '@/constants/api'
import { ConfigTableType, StatusWithdraw } from '@/constants/types'
import useModel from '@/context/ModelContext'
import moment from 'moment'
import { convertAddressArrToString } from '@/utils'
import SkeletonContainer from '@/components/SkeletonContainer'
import { IoIosArrowDown } from 'react-icons/io'
import { Button, DatePicker, Dropdown, Input, Space } from 'antd'
import { FiSearch } from 'react-icons/fi'

const WrapperStyled = styled(Grid)`
  width: 100%;
`

const SupportWrapperStyled = styled.section`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;

  .withdrawal-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 30px;

    & > h2 {
      font-size: 1.125rem;
      font-weight: 500;
    }

    .topHeaderBox {
      width: 100%;
      .title {
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 1.4;
      }
    }

    .bottomHeaderBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .inputEmailButton {
      margin: 15px 0;
      flex: 1;
      max-width: 300px;
      @media (max-width: 899px) {
        max-width: 100%;
      }
    }

    .rangePickerBox {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      margin: 0 15px;
    }

    .rangePicker {
      flex: 1;
      max-width: 150px;
      min-width: 140px;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: 899px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }
    }

    .inputBox {
      margin-left: 30px;
      justify-content: space-between;
      align-items: center;
      display: flex;

      @media (max-width: 899px) {
        flex-direction: column;
      }
    }

    .statusBox {
      display: flex;
      margin: 15px 0;
      gap: 10px;

      &:hover {
        cursor: pointer;
      }

      .statusBox-approved {
        padding: 0px 10px;
        border-right: 1px solid black;
        border-left: 1px solid black;
      }
    }
  }

  .row {
    &:hover {
      cursor: pointer;
      background-color: #eaecee;
    }
  }

  .create-link {
    display: flex;
    align-items: center;
    gap: 30px;

    & > div {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 10px;
      color: #298cfd;
      border: 1px solid #298cfd;
      border-radius: 6px;
      padding: 8px 16px;
      cursor: pointer;
      transition: all 10s ease;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        background: linear-gradient(0deg, #f0f7ff, #f0f7ff);
        opacity: 0;
        transition: all 500ms ease;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

const HistoryTableStyled = styled.div`
  margin-bottom: 40px;
  .row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dce0e3;
    color: #32373d;

    &.header {
      background-color: #e8eaed;
      font-weight: 500;
    }

    .cell {
      padding: 9px;
      min-width: calc((50%) / 3);

      &.req {
        color: #ff821c;
      }

      &.done {
        color: #34ac09;
      }
    }

    .cell-no {
      min-width: 3.5%;
    }

    .cell-title {
      min-width: 46.5%;
    }
  }
`

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DCE0E3',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&:hover': {
    cursor: 'pointer',
  },
}))

const NoDataBoxStyled = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-top: 10vh;
`

const withdrawRequestArrFakeData = [
  {
    id: '1111',
    email: 'blockbase1@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 200,
  },
  {
    id: '2222',
    email: 'blockbase2@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 400,
  },
  {
    id: '3333',
    email: 'blockbase3@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 300,
  },
  {
    id: '4444',
    email: 'blockbase4@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 500,
  },
  {
    id: '5555',
    email: 'blockbase5@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 600,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
  {
    id: '6666',
    email: 'blockbase6@gmail.com',
    address: '0x123123123123123123123123123',
    deadline: '10-7-2023',
    amount: 700,
  },
]

const AdminPayment: FC = () => {
  const addToast = useAddToast()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const useModal = useModel()
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [configTable, setConfigTable] = useState<ConfigTableType>({
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  })
  const [selected, setSelected] = useState<readonly string[]>([])
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [choosenAction, setChoosenAction] = useState<string>()
  const [choosenStatus, setChoosenStatus] = useState<string>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [withdrawRequestArr, setWithdrawRequestArr] = useState<
    RequestSupportArrType[]
  >(withdrawRequestArrFakeData)

  const getLinkUrlApi = async (page = 1) => {
    setIsLoadingTable(true)
    const overviewRes = await SupportService.getLinkRequestData()
    if (
      overviewRes &&
      overviewRes.data &&
      overviewRes.data.items &&
      overviewRes.data.items.length > 0
    ) {
      setConfigTable({
        pageIndex: overviewRes.data.pageIndex || page,
        pageSize: overviewRes.data.pageSize || 10,
        totalCount: overviewRes.data.totalCount,
        totalPages: overviewRes.data.totalPages,
      })
      setWithdrawRequestArr(overviewRes.data.items)
    } else {
      addToast({
        msg: overviewRes?.message || 'Can not get support request data!',
      })
    }
    setIsLoadingTable(false)
  }

  useEffect(() => {
    getLinkUrlApi()
  }, [])

  const onChangePagination = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    getLinkUrlApi(value)
  }

  const NoDataBox = () => {
    return (
      <NoDataBoxStyled>
        <span>There is no data!</span>
      </NoDataBoxStyled>
    )
  }

  // const onHandleOpenCreateRequestPopup = () => {
  //   useModal.setModelContent(<StatusRequestPopup />)
  //   useModal.showModal()
  // }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = withdrawRequestArrFakeData.map((n) => n.id)
      setSelected(newSelected)
      setIsSelectAll(true)
      return
    }
    setSelected([])
    setIsSelectAll(false)
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const items = [
    {
      label: 'Approve',
      key: '1',
      // icon: <IoIosArrowUp />,
    },
    {
      label: 'Reject',
      key: '2',
    },
  ]

  const menuProps = {
    items,
    onClick: () => {},
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeInputBox = (valueInput: any) => {
    if (valueInput.target.value) {
      // validateInputEmail(valueInput.target.value)
    }
  }

  const onHandleGetDataFromStatus = (newStatus: string) => {
    setChoosenStatus(newStatus)
  }

  return (
    <WrapperStyled>
      <SupportWrapperStyled>
        <div className="withdrawal-header">
          <div className="topHeaderBox">
            <span className="title">Withdrawal List</span>
            <div className="statusBox">
              <span
                onClick={() =>
                  onHandleGetDataFromStatus(StatusWithdraw.Waiting)
                }
                className="statusBox-waiting"
                style={
                  choosenStatus === StatusWithdraw.Waiting
                    ? { fontWeight: 500, textDecoration: 'underline' }
                    : {}
                }
              >
                {StatusWithdraw.Waiting} (43)
              </span>
              <span
                onClick={() =>
                  onHandleGetDataFromStatus(StatusWithdraw.Approved)
                }
                className="statusBox-approved"
                style={
                  choosenStatus === StatusWithdraw.Approved
                    ? { fontWeight: 500, textDecoration: 'underline' }
                    : {}
                }
              >
                {StatusWithdraw.Approved} (21)
              </span>
              <span
                onClick={() =>
                  onHandleGetDataFromStatus(StatusWithdraw.Rejected)
                }
                className="statusBox-rejected"
                style={
                  choosenStatus === StatusWithdraw.Rejected
                    ? { fontWeight: 500, textDecoration: 'underline' }
                    : {}
                }
              >
                {StatusWithdraw.Rejected} (36)
              </span>
            </div>
          </div>
          <div className="bottomHeaderBox">
            <Dropdown menu={menuProps}>
              <Button>
                <Space align="center">
                  Bulk Action
                  <IoIosArrowDown />
                </Space>
              </Button>
            </Dropdown>

            <div className="inputBox">
              <span>24 items</span>
              <div className="rangePickerBox">
                <DatePicker
                  suffixIcon={<span>From</span>}
                  placeholder="From date"
                  placement="bottomRight"
                  className="rangePicker"
                />
                <DatePicker
                  suffixIcon={<span>To</span>}
                  placeholder="To date"
                  placement="bottomRight"
                  className="rangePicker"
                />
              </div>

              <Input
                suffix={<FiSearch />}
                className="inputEmailButton"
                placeholder="Enter user email"
                allowClear
                onChange={onChangeInputBox}
              />
            </div>
          </div>
        </div>
        <SkeletonContainer isLoading={isLoadingTable}>
          <HistoryTableStyled>
            <TableContainer
              style={{ height: '60vh', width: '100%' }}
              component={Paper}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 700 }}
                aria-label="customized table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <Checkbox
                        checked={isSelectAll}
                        color="primary"
                        onChange={handleSelectAllClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell align="right">
                      Wallet address
                    </StyledTableCell>
                    <StyledTableCell align="right">Deadline</StyledTableCell>
                    <StyledTableCell align="right">
                      Amount (USDT)
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withdrawRequestArrFakeData ? (
                    withdrawRequestArrFakeData.map((row) => {
                      const isItemSelected = isSelected(row.id)

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          tabIndex={-1}
                          selected={isItemSelected}
                          key={row.id}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': row.id,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.email}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {convertAddressArrToString([row.address], 6, 4)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.deadline
                              ? moment(row.deadline).format(
                                  'DD-MM-YYYY HH:MM:ss',
                                )
                              : ''}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.amount}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })
                  ) : (
                    <NoDataBox />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </HistoryTableStyled>
        </SkeletonContainer>
        <div className="pagination">
          <Pagination
            disabled={!configTable.totalPages}
            onChange={onChangePagination}
            count={configTable.totalPages}
            shape="rounded"
            color={'greyPagination'}
          />
        </div>
      </SupportWrapperStyled>
    </WrapperStyled>
  )
}

export default AdminPayment
