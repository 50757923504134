import {
  CircularProgress,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import PaymentService from '@/services/paymentService'
import { useAddToast } from '@/state/application/hooks'
import { WithdrawnHistoryType } from '@/constants/api'
import { ConfigTableType } from '@/constants/types'
import SkeletonContainer from '@/components/SkeletonContainer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

// const dummy = [
//   {
//     id: 0,
//     transactionId: '#12343478',
//     amount: 221,
//     status: 'Success',
//     createdDateTime: '2023-06-27T08:22:49.289Z',
//   },
//   {
//     id: 1,
//     transactionId: '#12343423',
//     amount: 123,
//     status: 'Success',
//     createdDateTime: '2023-06-27T08:22:49.289Z',
//   },
// ]

const PaymentHistoryWrapperStyled = styled.section`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;

  @media only screen and (max-width: 899px) {
    padding: 1.5em;
    width: unset;
    margin: 0 0px 15px 0px;
  }

  @media (min-width: 900px) {
    margin-bottom: 15px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }

  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;

    & > h2 {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  .row {
    &:hover {
      cursor: pointer;
      background-color: #eaecee;
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

const HistoryTableStyled = styled.div`
  margin-bottom: 40px;
  .row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dce0e3;
    color: white;

    &.header {
      background-color: #e8eaed;
      font-weight: 500;
    }

    .cell {
      padding: 9px;
      min-width: calc(95.5% / 4);

      &.reject {
        color: #f42b2b;
      }

      &.success {
        color: #2fb301;
      }

      &.request {
        color: #ff821c;
      }

      &.approve {
        color: #0066f4;
      }
    }

    .cell-no {
      min-width: 4.5%;
    }
  }
`

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DCE0E3',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const LoadingBoxStyled = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoDataBoxStyled = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-top: 10vh;
`

const PaymentHistory: FC = () => {
  const addToast = useAddToast()
  const { t } = useTranslation()

  const [historyData, setHistoryData] = useState<WithdrawnHistoryType[]>()
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [configTable, setConfigTable] = useState<ConfigTableType>({
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  })

  const getHistoryApi = async (page = 1) => {
    setIsLoadingTable(true)
    const overviewRes = await PaymentService.getHistoryApi()
    if (overviewRes && overviewRes.items && overviewRes.items.length >= 0) {
      setConfigTable({
        pageIndex: overviewRes.pageIndex || page,
        pageSize: overviewRes.pageSize || 10,
        totalCount: overviewRes.totalCount,
        totalPages: overviewRes.totalPages,
      })
      setHistoryData(overviewRes.items)
    } else {
      addToast({
        msg: 'Can not get overview data!',
      })
    }
    setIsLoadingTable(false)
  }

  useEffect(() => {
    getHistoryApi()
  }, [])

  const onChangePagination = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    getHistoryApi(value)
  }

  const renderStatus = (status: string) => {
    switch (status) {
      case 'Rejected': {
        return <div className="cell reject">Rejected</div>
      }
      case 'Success': {
        return <div className="cell success">Success</div>
      }
      case 'Requested': {
        return <div className="cell request">Requested</div>
      }
      case 'Approved': {
        return <div className="cell approve">Approved</div>
      }
      default: {
        break
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const LoadingWaitingBox = () => {
    return (
      <LoadingBoxStyled>
        <Skeleton
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sx={{
            minWidth: 700,
            minHeight: '30vh',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            transform: 'scale(1)',
          }}
        >
          <CircularProgress
            style={{ visibility: 'inherit', opacity: 0.5 }}
            size={30}
            color="inherit"
          />
        </Skeleton>
      </LoadingBoxStyled>
    )
  }

  const NoDataBox = () => {
    return (
      <NoDataBoxStyled>
        <span>{t('noData')}</span>
      </NoDataBoxStyled>
    )
  }

  return (
    <PaymentHistoryWrapperStyled>
      <div className="history-header">
        <h2>{t('payment.withdrawalHistory')}</h2>
        <p>
          {configTable.totalCount} {t('items')}
        </p>
      </div>
      <SkeletonContainer isLoading={isLoadingTable}>
        <>
          <HistoryTableStyled>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700, minHeight: '30vh' }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('stt')}</StyledTableCell>
                    <StyledTableCell align="right">
                      {t('payment.creationTime')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t('payment.transactionID')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t('payment.amount')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t('status')}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {historyData && historyData.length > 0 ? (
                  <TableBody>
                    {historyData.map((row, rowIndex) => (
                      <StyledTableRow key={row.transactionId}>
                        <StyledTableCell component="th" scope="row">
                          {rowIndex}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.createdDateTime
                            ? moment(row.createdDateTime).format(
                                'DD-MM-YYYY HH:MM:ss',
                              )
                            : ''}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.transactionId}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.amount} USDT
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {renderStatus(row.status)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <NoDataBox key={'noData'} />
                )}
              </Table>
            </TableContainer>
          </HistoryTableStyled>
          <div className="pagination">
            <Pagination
              disabled={!configTable.totalPages}
              onChange={onChangePagination}
              count={configTable.totalPages}
              shape="rounded"
              color={'greyPagination'}
            />
          </div>
        </>
      </SkeletonContainer>
    </PaymentHistoryWrapperStyled>
  )
}

export default PaymentHistory
