import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import MacOS from '@/assets/icon-macos.svg'
import Window from '@/assets/icon-window.svg'
import { useRefCode } from '@/context/RefCodeProvider'
import { useCheckArchitecture } from '@/hooks/useCheckArchitecture'

const downloadUrl = 'https://laptop-updates.herond.org/download'

const LinkButtonStyled = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 220px;
  width: 100%;
  height: 50px;
  line-height: 27px;
  font-weight: 500;
  border-radius: 40px !important;
  text-transform: capitalize !important;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  & > div {
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:before {
    transition: all 200ms ease;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: linear-gradient(264.35deg, #ff821c 45.5%, #ffc46b 142.06%);
  }

  &.gray {
    border: 1px solid white;
  }

  &.gray:before {
    background: #222a34;
  }

  &.gray:hover {
    &:before {
      background: #161d26;
    }
  }

  &:hover {
    &:before {
      opacity: 0.9;
    }
  }
`
interface BrowserDownloadButtonProps {
  isSection?: boolean
}

const BrowserDownloadButton: React.FC<BrowserDownloadButtonProps> = ({
  isSection,
}) => {
  const { os } = useCheckArchitecture()
  const { t } = useTranslation()
  const { refCode } = useRefCode()

  return (
    <LinkButtonStyled
      href={`${downloadUrl}${refCode ? `/${refCode}` : '/Herond001'}`}
      target="_self"
      className={`${isSection ? 'gray' : ''} `}
    >
      <div>
        <img
          src={os === 'win' ? Window : os === 'mac' ? MacOS : ''}
          alt="platform"
          style={{ marginRight: '10px' }}
        />
        {t('header.download_herond')}
      </div>
    </LinkButtonStyled>
  )
}

export default BrowserDownloadButton
