import { AxiosError } from 'axios'

import { ToastOptions } from '@/constants/types'

// this interface is the default response data from ours api
export interface HttpData {
  code: string
  description?: string
  status: number
}

// this is all errrors allowed to receive
export type THttpError = Error | AxiosError | null

// object that can be passed to our registy
export interface ErrorHandlerObject {
  after?(error?: THttpError, options?: ErrorHandlerObject): void
  before?(error?: THttpError, options?: ErrorHandlerObject): void
  message?: string
  notify?: ToastOptions
}

//signature of error function that can be passed to ours registry
export type ErrorHandlerFunction = (
  error?: THttpError,
) => ErrorHandlerObject | boolean | undefined

//type that our registry accepts
export type ErrorHandler = ErrorHandlerFunction | ErrorHandlerObject | string

//interface for register many handlers once (object where key will be presented as search key for error handling
export interface ErrorHandlerMany {
  [key: string]: ErrorHandler
}

export class HttpError extends Error {
  constructor(message?: string) {
    super(message) // 'Error' breaks prototype chain here
    this.name = 'HttpError'
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export interface HttpResponse<T> {
  success?: boolean
  code?: string
  data?: T
  message?: string
}
