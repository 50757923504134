import { Skeleton } from '@mui/material'
import styled from 'styled-components'
// import { IoSettingsOutline } from 'react-icons/io5'
// import { VscChevronRight } from 'react-icons/vsc'
import { HiCurrencyDollar, HiUserGroup } from 'react-icons/hi'
import { BsPersonCheck } from 'react-icons/bs'
import { FC, useEffect, useState } from 'react'

// import copyIcon from '@/assets/icon-copy.svg'
import { useAddToast } from '@/state/application/hooks'
import { copyToClipboard, isValidateEmail } from '@/utils'
import OverviewService from '@/services/overviewService'

import { OverViewDataType } from '@/constants/api'
import InformationIcon from '@/components/InformationIcon'
import { useAuth } from '@/context/AuthProvider'
import { useNavigate } from 'react-router'
// import { DefaultRoute } from '@/constants/types'
import { useTranslation } from 'react-i18next'
import OverViewHistory from './components/OverViewHistory'
import { DatePicker, Input } from 'antd'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { RangePicker } = DatePicker

const WrapperStyled = styled.div`
  width: 100%;
  padding-bottom: 5vh;

  @media (max-width: 576px) {
    width: 100%;
    position: relative;
  }
`

const OverViewStyled = styled.div`
  :global {
    .ant-picker-panel-layout {
      flex-wrap: wrap !important;
    }
    .ant-picker-panels {
      flex-wrap: wrap !important;
    }
  }
  .overview-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;

    @media (max-width: 576px) {
      margin: 0px;
    }

    & > span {
      font-size: 1.875rem;
      font-weight: 500;
      line-height: 1.4;

      @media (max-width: 576px) {
        margin: 0 0 0px 0px;
      }
    }
  }

  .inputEmailButton {
    margin: 15px 0;
    flex: 1;
    max-width: 500px;
    @media (max-width: 899px) {
      max-width: 100%;
    }
  }

  .rangePickerBox {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 15px;
  }

  .rangePicker {
    flex: 1;
    max-width: 200px;
    @media (max-width: 899px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }

  .inputBox {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;

    @media (max-width: 899px) {
      flex-direction: column;
    }
  }
`

const OverviewTableBox = styled.div`
  display: flex;
  flex-direction: column;
`

const OverviewCountStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  display: inline-flex;
  flex-wrap: wrap;

  @media (max-width: 899px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 900px) {
    margin-bottom: 15px;
    gap: 15px;
  }

  @media (min-width: 1200px) {
    gap: 30px;
    margin-bottom: 30px;
  }

  .overview-item {
    display: flex;
    flex-direction: column;
    height: 100%;

    padding: 2vh 2vw;
    min-height: 180px;
    background-color: white;
    border-radius: 10px;
    flex: 1 0 auto;

    @media (max-width: 899px) {
      width: calc(100% - 3em);
      padding: 1.5em;
      min-height: unset;
    }

    @media (min-width: 900px) {
      /* flex: 0.5; */
      flex: 1;
      flex-basis: 40%;
    }

    @media (min-width: 1200px) {
      flex: 0.25;
      flex-basis: 15%;
    }

    & > p {
      font-size: 3.75rem;
      line-height: 1.4;
      font-weight: 500;
      margin: 0;

      @media (max-width: 899px) {
        font-size: 2.5;
      }

      @media (min-width: 900px) {
        /* flex: 0.5; */
        font-size: 2.5;
      }

      @media (min-width: 1200px) {
        font-size: 2.5;
      }
    }
  }

  .overview-item__header {
    flex: 1;
    align-self: 'top';
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    & > p {
      font-size: 1.2rem;
      font-weight: 500;

      @media (max-width: 899px) {
        font-size: 1.2rem;
      }

      @media (min-width: 900px) {
        /* flex: 0.5; */
        font-size: 1rem;
      }
      @media (min-width: 1300px) {
        font-size: 1.2rem;
      }
    }
  }

  .label-overview__header {
    font-size: 18px;
    font-weight: 500;
    color: black;
  }

  .number-overview__header {
    color: black;
    font-size: 60px;
  }

  .header--icon-box {
    display: flex;
    justify-content: 'center';
    align-items: 'center';
    padding: 7px;
    background-color: #fff3e9;
    border-radius: 100%;
  }

  .header--icon {
    aspect-ratio: 1 / 1;
    color: #ff821c;
  }
`

export const AdminUserDetail: FC = () => {
  const addToast = useAddToast()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, login } = useAuth()
  const [isLoadingApi, setIsLoadingApi] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingRegister, setIsLoadingRegister] = useState<boolean>(false)
  const [lastestCode, setLastestCode] = useState<string>('')
  const [overviewData, setOverviewData] = useState<OverViewDataType>({
    totalRecruited: undefined,
    toTalValid: undefined,
    totalEarned: undefined,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailInputTxt, setEmailInputTxt] = useState<string | undefined>(
    undefined,
  )

  const hasReferralCode = user ? user.hasReferralCode : false

  const fetchAllApi = async () => {
    setIsLoadingApi(true)
    const [lastestCodeRes, overviewRes] = await Promise.all([
      OverviewService.getLastestCode(),
      OverviewService.getOverViewData(),
    ])

    if (lastestCodeRes && lastestCodeRes.data) {
      setLastestCode(lastestCodeRes.data)
    } else {
      addToast({
        msg: lastestCodeRes?.message || 'Can not get lastest code!',
      })
    }

    if (overviewRes && overviewRes.data) {
      setOverviewData({
        toTalValid: overviewRes.data.toTalValid,
        totalEarned: overviewRes.data.totalEarned,
        totalRecruited: overviewRes.data.totalRecruited,
      })
    } else {
      addToast({
        msg: overviewRes?.message || 'Can not get overview data!',
      })
    }

    setIsLoadingApi(false)
  }

  useEffect(() => {
    if (hasReferralCode) {
      fetchAllApi()
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onHandleCopyLink = () => {
    copyToClipboard(`https://herond.org?ref=${lastestCode}`)
    addToast({
      msg: 'Copied',
    })
  }

  interface PropsOverviewBoxType {
    icon: JSX.Element
    title: string
    value: string | number | undefined
    informationTitle: string
    isLoading: boolean
  }

  const OverviewBox = (propsOverviewBox: PropsOverviewBoxType) => {
    const { icon, title, value, informationTitle, isLoading } = propsOverviewBox
    return (
      <div className="overview-item">
        <div className="overview-item__header">
          <div className="header--icon-box">{icon}</div>
          <p className="label-overview__header">{title}</p>
          <InformationIcon>{informationTitle}</InformationIcon>
        </div>
        {isLoading ? (
          <div className="loadingBox">
            <Skeleton
              variant="text"
              sx={{
                fontSize: '60px',
                height: '84px',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          </div>
        ) : (
          <p className="number-overview__header">{value}</p>
        )}
      </div>
    )
  }

  const validateInputEmail = (input: string) => {
    // handle error here
    return isValidateEmail(input)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeInputBox = (valueInput: any) => {
    if (valueInput.target.value) {
      validateInputEmail(valueInput.target.value)
    }
  }

  return (
    <WrapperStyled>
      <OverViewStyled>
        <div className="overview-header">
          <span>Affiliate user details</span>
          <div className="inputBox">
            <Input
              className="inputEmailButton"
              placeholder="Enter user email"
              allowClear
              onChange={onChangeInputBox}
            />

            <div className="rangePickerBox">
              <DatePicker
                placeholder="From date"
                placement="bottomRight"
                className="rangePicker"
              />
              <DatePicker
                placeholder="To date"
                placement="bottomRight"
                className="rangePicker"
              />
            </div>
          </div>
        </div>
        <OverviewCountStyled>
          <OverviewBox
            icon={<HiUserGroup size={24} className="header--icon" />}
            title={t('overview.recruitedUser')}
            value={overviewData.totalRecruited}
            informationTitle={t('overview.theTotalAmountOfUsers')}
            isLoading={isLoadingApi}
          />
          <OverviewBox
            icon={<BsPersonCheck size={24} className="header--icon" />}
            title={t('overview.validUser')}
            value={overviewData.toTalValid}
            informationTitle={t('overview.theTotalAmountEligibilityStandards')}
            isLoading={isLoadingApi}
          />
          <OverviewBox
            icon={<HiCurrencyDollar size={24} className="header--icon" />}
            title={`${t('overview.earnMoney')} USDT`}
            value={overviewData.totalEarned}
            informationTitle={t('overview.theTotalAmountMoney')}
            isLoading={isLoadingApi}
          />
          <OverviewBox
            icon={<HiCurrencyDollar size={24} className="header--icon" />}
            title={`${t('overview.earnMoney')} USDT`}
            value={overviewData.totalEarned}
            informationTitle={t('overview.theTotalAmountMoney')}
            isLoading={isLoadingApi}
          />
        </OverviewCountStyled>

        <OverviewTableBox>
          <OverViewHistory />
          <OverViewHistory />
          <OverViewHistory />
        </OverviewTableBox>
      </OverViewStyled>
    </WrapperStyled>
  )
}

export default AdminUserDetail
