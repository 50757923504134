import { Grid, Skeleton } from '@mui/material'
import styled from 'styled-components'
import { HiCurrencyDollar } from 'react-icons/hi'
import { useEffect, useState } from 'react'

import userMoneyIcon from '@/assets/icon-user-money.svg'
import widthdrawWalletIcon from '@/assets/icon-withdraw-wallet.svg'
import PaymentService from '@/services/paymentService'
import { useAddToast } from '@/state/application/hooks'

import PaymentHistory from './PaymentHistory'
import { PaymentOverviewType } from '@/constants/api'
import InformationIcon from '@/components/InformationIcon'
import { useTranslation } from 'react-i18next'

const WrapperStyled = styled(Grid)`
  width: 100%;
`

const WithdrawInstructionStyled = styled.div`
  background-color: white;
  padding: 30px 40px;
  border-radius: 10px;

  @media (max-width: 899px) {
    margin: 0px;
    margin-bottom: 15px;
  }

  @media (min-width: 900px) {
    margin-bottom: 15px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 30px;
  }

  & > h3 {
    font-size: 1.125 rem;
    font-weight: 500;
    margin-bottom: 35px;
  }

  & > p {
    color: #32373d;
    margin-bottom: 10px;
    line-height: 1.5;
  }
`

const PaymentStyled = styled.div`
  .payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-size: 1.875rem;
      font-weight: 500;
      line-height: 1.4;
    }
  }
`

const PaymentCountStyled = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 899px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 900px) {
    gap: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 1200px) {
    gap: 30px;
    margin-bottom: 30px;
  }

  .payment-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2vh 2vw;
    background-color: white;
    border-radius: 10px;

    @media (max-width: 899px) {
      margin: 0px;
      width: unset;
      padding: 1.5em;
    }

    & > p {
      font-size: 3.75rem;
      line-height: 1.4;
      font-weight: 500;
      margin: 0;

      @media (max-width: 899px) {
        font-size: 3rem;
      }
    }
  }

  .payment-item__header {
    flex: 1;
    align-self: 'top';
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    & > p {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .number-overview__header {
    color: black;
    font-size: 60px;
  }

  .loadingBox {
    .loadingTxt {
    }
  }

  .header--icon-box {
    margin-top: 1.1em;
    display: flex;
    justify-content: 'center';
    align-items: 'center';
    border-radius: 100%;
  }

  .header--icon {
    font-size: 24px;
    padding: 8px;
    color: #ff821c;
    background-color: #fff3e9;
    border-radius: 50%;
  }

  .header--information {
    color: #a1a9b5;
    font-size: 1.25rem;
  }
`

const Payment = () => {
  const addToast = useAddToast()
  const { t } = useTranslation()
  const [isLoadingApi, setIsLoaadingApi] = useState<boolean>(true)
  const [overviewData, setOverviewData] = useState<PaymentOverviewType>({
    earnedMoney: undefined,
    withdrawn: undefined,
  })

  useEffect(() => {
    const getOverViewApi = async () => {
      setIsLoaadingApi(true)
      const overviewRes = await PaymentService.getOverViewData()
      if (overviewRes) {
        setOverviewData(overviewRes)
      } else {
        addToast({
          msg: 'Can not get overview data!',
        })
      }

      setIsLoaadingApi(false)
    }

    getOverViewApi()
  }, [])

  interface PropsOverviewBoxType {
    icon: JSX.Element
    title: string
    value: string | number | undefined
    informationTitle?: string | undefined
    isLoading: boolean
  }

  const OverviewBox = (propsOverviewBox: PropsOverviewBoxType) => {
    const { icon, title, value, informationTitle, isLoading } = propsOverviewBox
    return (
      <div className="payment-item">
        <div className="payment-item__header">
          <div className="header--icon-box">{icon}</div>
          <p className="label-overview__header">{title}</p>
          {informationTitle && (
            <InformationIcon>{informationTitle}</InformationIcon>
          )}
        </div>
        {isLoading ? (
          <div className="loadingBox">
            <Skeleton
              variant="text"
              sx={{
                fontSize: '60px',
                height: '84px',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          </div>
        ) : (
          <p className="number-overview__header">{value}</p>
        )}
      </div>
    )
  }

  return (
    <WrapperStyled container spacing={'30px'}>
      <Grid item xs={12}>
        <PaymentStyled>
          <PaymentCountStyled>
            <OverviewBox
              icon={
                <img src={userMoneyIcon} alt="user" className="header--icon" />
              }
              title={`${t('payment.eachValidUser')} (USDT)`}
              value={0.5}
              isLoading={isLoadingApi}
            />
            <OverviewBox
              icon={<HiCurrencyDollar className="header--icon" />}
              title={`${t('overview.earnMoney')} (USDT)`}
              value={overviewData.earnedMoney}
              isLoading={isLoadingApi}
            />
            <OverviewBox
              icon={
                <img
                  src={widthdrawWalletIcon}
                  alt={t('payment.withdrawn') || 'withdrawn'}
                  className="header--icon"
                />
              }
              title={`${t('payment.withdrawn')} (USDT)`}
              value={overviewData.withdrawn}
              isLoading={isLoadingApi}
            />
          </PaymentCountStyled>

          <WithdrawInstructionStyled>
            <h3>{t('payment.withdrawalInstructions')}</h3>
            <p>{t('payment.withdrawalInstructions1')}</p>
            <p>{t('payment.withdrawalInstructions2')}</p>
            <p>{t('payment.withdrawalInstructions3')}</p>
            <p>{t('payment.withdrawalInstructions4')}</p>
          </WithdrawInstructionStyled>
          <PaymentHistory />
        </PaymentStyled>
      </Grid>
    </WrapperStyled>
  )
}

export default Payment
