import { ReactComponent as PaymentIcon } from '@/assets/icon-payment.svg'
import { ReactComponent as LinkIcon } from '@/assets/icon-link.svg'
import { ReactComponent as PolicyIcon } from '@/assets/icon-policy.svg'
// import { ReactComponent as SupportIcon } from '@/assets/icon-support.svg'
import { ReactComponent as OverViewIcon } from '@/assets/icon-overview.svg'

import { DefaultRoute } from './constants/types'
import { ReactNode } from 'react'
import images from './utils/images'
import Image from './components/Image'

export interface NavLink {
  title: string
  isExternal: boolean
  to?: string
  href?: string
  icon?: ReactNode
}

interface Configurations {
  baseApiUrl?: string
  accountsUrl?: string
  downloadLink: {
    [platform: string]: string
  }
  navLink: NavLink[]
  dashboardNavLink: NavLink[]
  adminNavLink: NavLink[]
}

const config: Configurations = {
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,
  accountsUrl: process.env.REACT_APP_ACCOUNT_BASE_URL,
  downloadLink: {
    win: 'https://herond-omaha-staging.s3.ap-southeast-1.amazonaws.com/omaha/media/build/Herond/x64-staging/win/HerondBrowserSetup_106_0_1_7.exe',
    mac: 'https://herond-omaha-staging.s3.ap-southeast-1.amazonaws.com/mac-static/herond-browser-106-0-5249-55.pkg',
    // linux: 'https://herond-omaha-staging.s3.ap-southeast-1.amazonaws.com/omaha/media/build/Herond/x64-staging/mac/HerondBrowserSetup.exe',
  },
  navLink: [
    {
      title: 'header.home',
      to: 'https://herond.org/',
      isExternal: false,
    },
    {
      title: 'header.features',
      to: 'https://herond.org/#features',
      isExternal: false,
    },
    {
      title: 'header.support',
      to: 'https://help.herond.org/',
      isExternal: false,
    },
    {
      title: 'header.earn_reward',
      to: 'https://affiliate.herond.org/dashboard/overview',
      isExternal: false,
    },
    // {
    //   title: 'header.deck',
    //   to: 'https://docsend.com/view/sxmwzqxmj9jrvi4h',
    //   isExternal: false,
    // },
    // {
    //   id: 2,
    //   type: 'internal',
    //   title: 'Trình duyệt Herond',
    //   to: '/introduction',
    // },
    // {
    //   id: 3,
    //   type: 'internal',
    //   title: 'Tính năng',
    //   to: '/introduction',
    // },
    // {
    //   id: 4,
    //   type: 'internal',
    //   title: 'Hỗ trợ',
    //   to: '/introduction',
    // },
    // {
    //   type: "external",
    //   title: "services",
    //   href: "/services",
    // },
    // {
    //   type: "external",
    //   title: "technology",
    //   href: "/technology",
    // },
    // {
    //   type: "external",
    //   title: "portfolio",
    //   href: "/portfolio",
    // },
    // {
    //   type: "external",
    //   title: "news",
    //   href: "/news",
    // },
    // {
    //   type: "external",
    //   title: "career",
    //   href: "/career",
    // },
  ],
  dashboardNavLink: [
    {
      title: 'dashboard.header.overview',
      to: DefaultRoute.Overview,
      isExternal: true,
      icon: <OverViewIcon />,
    },
    {
      title: 'dashboard.header.payment',
      to: DefaultRoute.Payment,
      isExternal: true,
      icon: <PaymentIcon />,
    },
    {
      title: 'dashboard.header.link-management',
      to: DefaultRoute.LinkManageMent,
      isExternal: true,
      icon: <LinkIcon />,
    },
    {
      title: 'dashboard.header.policy',
      to: DefaultRoute.Policy,
      isExternal: true,
      icon: <PolicyIcon />,
    },
    // {
    //   title: 'dashboard.header.support',
    //   to: DefaultRoute.Support,
    //   isExternal: false,
    //   icon: <SupportIcon />,
    // },
  ],
  adminNavLink: [
    {
      title: 'dashboard.header.admin-overview',
      to: DefaultRoute.AdminOverview,
      isExternal: true,
      icon: <OverViewIcon />,
    },
    {
      title: 'dashboard.header.user-detail',
      to: DefaultRoute.UserDetail,
      isExternal: true,
      icon: <Image src={images.peopleIcon} />,
    },
    {
      title: 'dashboard.header.withdrawal-manage',
      to: DefaultRoute.WithdrawalManage,
      isExternal: true,
      icon: <PaymentIcon />,
    },
  ],
}

export default config
