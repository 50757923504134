import {
  FC,
  PropsWithChildren,
  useEffect,
  createContext,
  useState,
  useContext,
} from 'react'
import { getCookie } from '@/utils/cookie'

interface RefCodeContextType {
  refCode: string | undefined
}

const defaultValue: RefCodeContextType = {
  refCode: undefined,
}

export const RefCodeContext = createContext(defaultValue)

export const useRefCode = () => {
  const context = useContext(RefCodeContext)

  if (!context) {
    throw new Error('Please use RefCodeProvider in parent component')
  }

  return {
    refCode: context.refCode,
  }
}

export const RefCodeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [refCode, setRefCode] = useState<string | undefined>()

  useEffect(() => {
    try {
      const currentRefCode = getCookie('HEROND_REF_CODE')
      if (currentRefCode) {
        setRefCode(currentRefCode)
      }
    } catch (e) {
      //
    }
  }, [])

  return (
    <RefCodeContext.Provider value={{ refCode }}>
      {children}
    </RefCodeContext.Provider>
  )
}

export default RefCodeProvider
