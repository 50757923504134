import { useCallback, useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'

export const useCheckArchitecture = () => {
  const [arch, setArch] = useState<'arm64' | 'x86' | 'x64' | undefined>()
  const [os, setOS] = useState<'mac' | 'win' | undefined>()

  const doCheck = useCallback(async () => {
    // check cpu architect
    try {
      const res = await navigator.userAgentData.getHighEntropyValues([
        'architecture',
      ])
      if (res.architecture === 'arm') {
        setArch('arm64')
      }
      if (res.architecture === 'x86') {
        setArch('x86')
      }
      if (res.architecture === 'x64') {
        setArch('x64')
      }
    } catch (e) {
      //
    }

    // check os
    const ua = new UAParser()
    const uaOS = ua.getOS()
    if (uaOS.name === 'Mac OS') {
      setOS('mac')
    } else if (uaOS.name === 'Windows') {
      setOS('win')
    }
  }, [])

  useEffect(() => {
    doCheck()
  }, [doCheck])

  return { arch, os }
}
