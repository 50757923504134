import styled from '@emotion/styled'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

interface FooterMenuProps {
  title: string
  items: { label: string; link?: string; type: 'internal' | 'external' }[]
  menuHidden: string[]
  handleMenu: (menu: string) => void
}

const StyledMenu = styled.div`
  @media only screen and (max-width: 576px) {
    border-bottom: 1px solid #dce0e3;
  }
`

const StyledMenuTitle = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .iconTitle {
    display: none;
  }

  @media only screen and (max-width: 576px) {
    margin: 16px 0;
    cursor: pointer;

    .iconTitle {
      display: block;
    }
  }
`

const StyledWrapperMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  transition: all 500ms ease;
  @media only screen and (max-width: 576px) {
    max-height: 300px;
    overflow: hidden;
    &.hidden {
      max-height: 0;
    }
  }
`

const StyledMenuItemLink = styled.a`
  text-decoration: none;
  color: inherit;
  line-height: 1.5;
  font-size: 1rem;
  transition: color 500ms ease;
  margin-bottom: 5px;
  display: inline-block;
  &:hover {
    color: #ff821c;
  }

  @media screen and (max-width: 992px) {
    font-size: 0.875em;
  }

  @media only screen and (max-width: 576px) {
    font-size: 1em;
  }
`

const StyledMenuItem = styled.div`
  line-height: 1.5;
  color: #a1a9b5;
  margin-bottom: 5px;
  @media screen and (max-width: 992px) {
    font-size: 0.875em;
  }

  @media screen and (max-width: 576px) {
    font-size: 1em;
  }
`

const FooterMenu: FC<FooterMenuProps> = ({
  title,
  items,
  menuHidden,
  handleMenu,
}) => {
  const { t } = useTranslation()

  return (
    <StyledMenu>
      <StyledMenuTitle onClick={() => handleMenu(title)}>
        {t(title)}
        <div className="iconTitle">
          {menuHidden.includes(title) ? (
            <RiArrowUpSLine />
          ) : (
            <RiArrowDownSLine />
          )}
        </div>
      </StyledMenuTitle>
      <StyledWrapperMenuItem
        className={`${menuHidden.includes(title) ? 'hidden' : ''}`}
      >
        {items.map((item, idx) => (
          <Fragment key={idx}>
            {item.link ? (
              <StyledMenuItemLink
                key={idx}
                href={item.link}
                target={item.type === 'external' ? '_blank' : '_self'}
              >
                {t(item.label!)}
              </StyledMenuItemLink>
            ) : (
              <StyledMenuItem>{t(item.label!)}</StyledMenuItem>
            )}
          </Fragment>
        ))}
      </StyledWrapperMenuItem>
    </StyledMenu>
  )
}

export default FooterMenu
