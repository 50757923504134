import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios'

import config from '@/config'

import { globalHandlers, dealWith } from './errorHandlers'
import { HttpError, HttpResponse, THttpError } from './types'

// this interceptor is used to handle all success ajax request
// we use this to check if status code is 200 (success), if not, we throw an HttpError
// to our error handler take place.
function responseHandler(response: AxiosResponse) {
  const configResponse = response?.config
  if (configResponse.raw) {
    return response
  }
  if (response.status === 200) {
    const data = response?.data
    if (!data) {
      throw new HttpError('API Error. No data!')
    }
    return response
  }

  throw new HttpError('API Error! Invalid status code!')
}

class Http {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.baseApiUrl,
      timeout: 10000,
    })

    this.axiosInstance.defaults.headers.common['X-Requested-With'] =
      'XMLHttpRequest'

    const responseError = (error: THttpError) =>
      globalHandlers.resposeErrorHandler(error)
    this.axiosInstance.interceptors.response.use(responseHandler, responseError)
  }

  handleResponse(res: AxiosResponse) {
    if (res.data.success) {
      return res.data
    }
    if (!res.data.success && res.data.code && res.data.message) {
      dealWith({ [res.data.code]: { message: res.data.message } })
      return { success: false, code: res.data.code, message: res.data.message }
    }
    dealWith({ UNKNOWN_ERROR: { message: 'unknown_error' } })
    return { success: false, code: 'UNKNOWN_ERROR', message: 'UNKNOWN_ERROR' }
  }

  async get<T, D = undefined>(
    url: string,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.get<HttpResponse<T>>(
      url,
      configRequest,
    )
    return this.handleResponse(res)
  }

  async post<T, D>(
    url: string,
    data?: D,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.post<HttpResponse<T>>(
      url,
      data,
      configRequest,
    )
    return this.handleResponse(res)
  }

  async put<T, D>(
    url: string,
    data?: D,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.put<HttpResponse<T>>(
      url,
      data,
      configRequest,
    )
    return this.handleResponse(res)
  }

  async delete<T, D>(
    url: string,
    configRequest?: AxiosRequestConfig<D>,
  ): Promise<HttpResponse<T>> {
    const res = await this.axiosInstance.delete<HttpResponse<T>>(
      url,
      configRequest,
    )
    return this.handleResponse(res)
  }
}

function createHttpInstance() {
  return new Http()
}

export const http: Http = createHttpInstance()

export default http
