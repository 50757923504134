import { useEffect, useState } from 'react'
import style from './style.module.scss'
import OverviewService from '@/services/overviewService'
import { useAddToast } from '@/state/application/hooks'
import tokenIcon from '@/assets/icon-token.svg'
import useModel from '@/context/ModelContext'
import { RequestConfirmationPopup } from '@/views/Overview/components/RequestConfirmationPopup'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

export const BalanceBox = () => {
  const addToast = useAddToast()
  const { t } = useTranslation()
  const useModal = useModel()
  const [balance, setBalance] = useState<number>(0)
  const isDisableWithdrawl = true

  const getBalance = async () => {
    const balanceRes = await OverviewService.getBalanceData()

    if (balanceRes !== undefined && balanceRes >= 0) {
      setBalance(balanceRes)
    } else {
      addToast({
        msg: 'Can not get balance user!',
      })
    }
  }

  useEffect(() => {
    getBalance()
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onHandleOpenWithdrawPopup = () => {
    useModal.setModelContent(<RequestConfirmationPopup />)
    useModal.showModal()
  }

  return (
    <div className={style.container}>
      <h3>{t('myBalance')}</h3>
      <p>{balance}</p>
      <div>
        <img src={tokenIcon} alt="" />
        USDT
      </div>
      {balance >= 10 && (
        <Button
          title="Withdraw"
          styleCss={{
            backgroundColor: 'white',
            color: '#298cfd',
            borderRadius: '40px',
          }}
          disabled={isDisableWithdrawl}
          onClick={onHandleOpenWithdrawPopup}
        />
      )}
      {/* <button type="button" onClick={onHandleOpenWithdrawPopup}>
        Withdraw
      </button> */}
    </div>
  )
}

export default BalanceBox
