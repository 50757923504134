// LandingPage
export enum LandingPageApi {
  Overview = '/Dashboard',
}

// OverView
export enum OverViewAPI {
  historyUser = '/Dashboard/recruited',
  overviewData = '/Dashboard/overview',
  chartData = '/Dashboard/valid-history',
  lastestCode = '/Code/latest',
}

export enum PaymentAPI {
  balanceUser = '/Payment/balance',
  withdrawalHistory = '/Payment/withdraw-history',
  overviewData = '/Payment/summary',
}

export enum LinkManagementAPI {
  createCode = '/Code',
  getCode = '/Code',
  checkingCode = '/Code/check',
  deleteCode = '/Code',
}

export enum UserAPI {
  registration = '/User/registration',
}

export enum SupportAPI {
  requestSupport = '/Support/search',
}

export interface OverViewDataType {
  totalRecruited?: number
  toTalValid?: number
  totalEarned?: number
}

export interface OverViewHistoryUserType {
  id?: number
  createdDateTime?: string
  address?: string
  status?: string
}

export interface OverViewHistoryRecruitedType {
  pageIndex: number
  pageSize: number
  totalCount: number
  totalPages: number
  items: OverViewHistoryUserType[] | undefined
}

export interface OverViewDataChartType {
  date: string
  value: number
}

// Landingpage
export interface OverViewLandingPageType {
  totalEarn: number
  avgMonth: number
  totalAffiliate: number
}

// LinkManagement
export interface LinkAffiliateArrType {
  createdDateTime?: string
  referralCode: string
  clickCount: number
  downloadCount: number
  validUser: number
  earnedMoney?: number
}

export interface ReferralCode {
  referralCode: string
}

// Payment

export interface PaymentBalanceType {
  recruitedUser?: number
  validUser?: number
  earnedMoney?: number
}

export interface PaymentOverviewType {
  earnedMoney?: number
  withdrawn?: number
}

export interface WithdrawnHistoryType {
  id: number
  transactionId: string
  amount: number
  status: string
  createdDateTime: string
}

export interface WithdrawnHistoryTableType {
  pageIndex: number
  pageSize: number
  totalCount: number
  totalPages: number
  items: WithdrawnHistoryType[] | undefined
}

// Support
export interface RequestSupportArrType {
  id?: string
  request?: string
  title?: string
  createdDateTime?: string
  status?: boolean | number
}

export interface RequestSupportResponseType {
  pageIndex: number
  pageSize: number
  totalCount: number
  totalPages: number
  items: RequestSupportArrType[] | undefined
}
