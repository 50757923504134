/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress, Skeleton } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'

interface PaginationProps {
  isLoading?: boolean
  children: JSX.Element
}

const SkeletonContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const MaskBackgroundStyle = styled.div`
  position: 'absolute';
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 100%;
  width: 100%;
  z-index: 100 !important;
  position: absolute;
  background-color: rgba(245, 245, 245, 0.6);
`

const SkeletonContainer: FC<PaginationProps> = (props: PaginationProps) => {
  const { isLoading = true, children } = props
  return (
    <SkeletonContainerStyled>
      {isLoading && (
        <MaskBackgroundStyle>
          <CircularProgress
            style={{ visibility: 'inherit', opacity: 0.5 }}
            size={30}
            color="inherit"
          />
        </MaskBackgroundStyle>
      )}
      {children}
    </SkeletonContainerStyled>
  )
}

export default SkeletonContainer
