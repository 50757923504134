import Stack from '@mui/material/Stack'
import styled from 'styled-components'
import { Button, Slide } from '@mui/material'
import { useEffect, useState } from 'react'
import SnackbarContent from '@mui/material/SnackbarContent'

import { useRemoveToast, useToasts } from '@/state/application/hooks'
import { Toast } from '@/constants/types'

const DURATION = 200

const ToastItem = (props: { item: Toast }) => {
  const removeToast = useRemoveToast()
  const [isShow, setIsShow] = useState<boolean>(false)
  const [timeoutShow, setTimeoutShow] = useState<NodeJS.Timeout>()
  const [timeoutRemove, setTimeoutRemove] = useState<NodeJS.Timeout>()

  useEffect(() => {
    setIsShow(true)
    setTimeoutShow(
      setTimeout(() => {
        setIsShow(false)
      }, props.item.options?.timeout || 4000),
    )
    setTimeoutRemove(
      setTimeout(() => {
        removeToast(props.item.id)
      }, (props.item.options?.timeout || 4000) + DURATION),
    )
  }, [])

  const handleClose = () => {
    clearTimeout(timeoutShow)
    clearTimeout(timeoutRemove)
    setIsShow(false)
    setTimeout(() => {
      removeToast(props.item.id)
    }, DURATION)
  }

  const action = (
    <Button onClick={handleClose} color="secondary" size="small">
      Close
    </Button>
  )

  return (
    <Slide
      timeout={DURATION}
      direction="right"
      in={isShow}
      mountOnEnter
      unmountOnExit
    >
      <SnackbarContent message={props.item.msg} action={action} />
    </Slide>
  )
}

const StyledStack = styled(Stack)`
  flex-direction: column-reverse !important;
  max-width: 600px;
  position: fixed;
  z-index: 10000;
  left: 15px;
  bottom: 15px;
  > .MuiSnackbarContent-root {
    margin-top: 16px;
  }
`

export const Toasts = () => {
  const toasts = useToasts()

  return (
    <StyledStack spacing={2}>
      {toasts.map((x) => (
        <ToastItem key={x.id} item={x} />
      ))}
    </StyledStack>
  )
}

export default Toasts
