import useModel from '@/context/ModelContext'
import images from '@/utils/images'
import styled from 'styled-components'
import image from '@/assets/welcome-desc.png'

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &:hover {
    cursor: pointer;
    background-color: #e4e4e4;
    border-radius: 20%;
  }
`

const RequestModalStyled = styled.section`
  position: fixed;
  z-index: 100;
  padding: 30px;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  width: 780px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const RequestContent = styled.div`
  display: flex;
  flex-direction: column;
  & > h2 {
    font-size: 1.125rem;
    font-weight: 1.6;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .status-item {
    margin-bottom: 30px;
    width: 100%;

    &.time {
      display: flex;

      .time,
      .status {
        width: 50%;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: #40474f;
      margin: 0;

      &.color {
        color: #ff821c;
      }
    }

    img {
      width: 100%;
      max-height: 400px;
    }
  }
`

export const StatusRequestPopup = () => {
  const useModal = useModel()
  const hideModal = () => {
    useModal.setModelContent(undefined)
    useModal.hideModal()
  }

  return (
    <RequestModalStyled onClick={(e) => e.preventDefault()}>
      <CloseButton onClick={hideModal}>
        <img
          src={images.closeButtonIcon}
          alt=""
          style={{ width: '20px', height: '20px' }}
        />
      </CloseButton>
      <RequestContent>
        <h2>Request #23613761</h2>
        <div className="status-item time">
          <div className="time">
            <h6>Creation Time</h6>
            <p>23/06/3023 09:34:12</p>
          </div>
          <div className="status">
            <h6>Status</h6>
            <p className="color">Requested</p>
          </div>
        </div>
        <div className="status-item">
          <div className="time">
            <h6>Title</h6>
            <p>Praesent laoreet eros non est commodo interdum</p>
          </div>
        </div>
        <div className="status-item">
          <h6>Request</h6>
          <p>
            Praesent laoreet eros non est commodo interdum. Curabitur vitae
            aliquet nibh, vel fermentum turpis. Integer sit amet diam arcu.
            Maecenas commodo mi diam, sed cursus magna sollicitudin non. Donec
            tempus hendrerit ipsum. Suspendisse pulvinar orci dictum door
            rhoncus tincidunt.
          </p>
        </div>
        <div className="status-item">
          <h6>Image</h6>
          <img src={image} alt="image-feedback" />
        </div>
      </RequestContent>
    </RequestModalStyled>
  )
}
