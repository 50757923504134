import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react'
import Box from '@mui/material/Box'
import styled from 'styled-components'
interface ModelState {
  hideModal(): void
  showModal(): void
  setModelContent(body?: JSX.Element): void
  setModelConfig(config?: object): void
}
const ModelContext = createContext<ModelState | null>(null)

const defaultConfig = {
  'aria-labelledby': 'child-modal-title',
  'aria-describedby': 'child-modal-description',
}

const ModalCustomPopup = styled.div`
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: 24;

  @media (max-width: 576px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }
`

const useModel = () => {
  const context = useContext(ModelContext)

  if (!context) {
    throw new Error('Please use ModelProvider in parent component')
  }

  return {
    hideModal: context.hideModal,
    showModal: context.showModal,
    setModelContent: context.setModelContent,
    setModelConfig: context.setModelConfig,
  }
}

export const ModelProvider = (props: PropsWithChildren) => {
  const [isShow, setIsShow] = useState(false)
  const [modelContent, setModelContent] = useState<JSX.Element | undefined>(
    undefined,
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modelConfig, setModelConfig] = useState<object>(defaultConfig)

  const hideModal = () => {
    setIsShow(false)
  }

  const showModal = () => {
    setIsShow(true)
  }

  const DefaultModal = () => {
    const style = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '1px solid white',
      borderRadius: '8px',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    }

    return <Box sx={{ ...style, width: 500 }} />
  }

  return (
    <ModelContext.Provider
      value={{ hideModal, showModal, setModelContent, setModelConfig }}
    >
      {props.children}
      <ModalCustomPopup
        style={isShow ? { display: 'flex' } : { display: 'none' }}
      >
        {modelContent ? modelContent : <DefaultModal />}
      </ModalCustomPopup>
    </ModelContext.Provider>
  )
}

export default useModel
