import { Outlet } from 'react-router'

import AuthProvider from '@/context/AuthProvider'

import Toasts from '../Toasts'
import BrowserCheckProvider from '@/context/BrowserCheckProvider'
import RefCodeProvider from '@/context/RefCodeProvider'
import LangCheckProvider from '@/context/LangCheckProvider'

const LayoutRoot = () => {
  return (
    <LangCheckProvider>
      <RefCodeProvider>
        <BrowserCheckProvider>
          <AuthProvider>
            <Toasts />
            <Outlet />
          </AuthProvider>
        </BrowserCheckProvider>
      </RefCodeProvider>
    </LangCheckProvider>
  )
}

export default LayoutRoot
