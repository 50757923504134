import {
  Pagination,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'

import React, { FC, useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import styled from 'styled-components'

import SupportService from '@/services/supportService'
import { useAddToast } from '@/state/application/hooks'
import { RequestSupportArrType } from '@/constants/api'
import { ConfigTableType } from '@/constants/types'
import useModel from '@/context/ModelContext'
import { StatusRequestPopup } from './components/StatusRequestPopup'
import moment from 'moment'

const WrapperStyled = styled(Grid)`
  width: 100%;
`

const SupportWrapperStyled = styled.section`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;

  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & > h2 {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  .row {
    &:hover {
      cursor: pointer;
      background-color: #eaecee;
    }
  }

  .create-link {
    display: flex;
    align-items: center;
    gap: 30px;

    & > div {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 10px;
      color: #298cfd;
      border: 1px solid #298cfd;
      border-radius: 6px;
      padding: 8px 16px;
      cursor: pointer;
      transition: all 10s ease;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        background: linear-gradient(0deg, #f0f7ff, #f0f7ff);
        opacity: 0;
        transition: all 500ms ease;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

const HistoryTableStyled = styled.div`
  margin-bottom: 40px;
  .row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dce0e3;
    color: #32373d;

    &.header {
      background-color: #e8eaed;
      font-weight: 500;
    }

    .cell {
      padding: 9px;
      min-width: calc((50%) / 3);

      &.req {
        color: #ff821c;
      }

      &.done {
        color: #34ac09;
      }
    }

    .cell-no {
      min-width: 3.5%;
    }

    .cell-title {
      min-width: 46.5%;
    }
  }
`

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DCE0E3',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const LoadingBoxStyled = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoDataBoxStyled = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-top: 10vh;
`

const Support: FC = () => {
  const addToast = useAddToast()
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [configTable, setConfigTable] = useState<ConfigTableType>({
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  })

  const [requestSupportArr, setRequestSupportArr] =
    useState<RequestSupportArrType[]>()

  const getLinkUrlApi = async (page = 1) => {
    setIsLoadingTable(true)
    const overviewRes = await SupportService.getLinkRequestData()
    if (
      overviewRes &&
      overviewRes.data &&
      overviewRes.data.items &&
      overviewRes.data.items.length > 0
    ) {
      setConfigTable({
        pageIndex: overviewRes.data.pageIndex || page,
        pageSize: overviewRes.data.pageSize || 10,
        totalCount: overviewRes.data.totalCount,
        totalPages: overviewRes.data.totalPages,
      })
      setRequestSupportArr(overviewRes.data.items)
    } else {
      addToast({
        msg: overviewRes?.message || 'Can not get support request data!',
      })
    }
    setIsLoadingTable(false)
  }

  useEffect(() => {
    getLinkUrlApi()
  }, [])

  const onChangePagination = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    getLinkUrlApi(value)
  }

  const LoadingWaitingBox = () => {
    return (
      <LoadingBoxStyled>
        <Skeleton
          style={{
            width: '100%',
            height: '100%',
          }}
          sx={{
            height: '60vh',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            transform: 'scale(1)',
          }}
        />
      </LoadingBoxStyled>
    )
  }

  const NoDataBox = () => {
    return (
      <NoDataBoxStyled>
        <span>There is no data!</span>
      </NoDataBoxStyled>
    )
  }

  const useModal = useModel()
  const onHandleOpenCreateRequestPopup = () => {
    useModal.setModelContent(<StatusRequestPopup />)
    useModal.showModal()
  }

  return (
    <WrapperStyled>
      <SupportWrapperStyled>
        <div className="history-header">
          <h2>Support Request List</h2>
          <div className="create-link">
            <p>{configTable.totalCount} items</p>
            <div onClick={onHandleOpenCreateRequestPopup}>
              <AiOutlinePlus /> Create Request
            </div>
          </div>
        </div>
        {isLoadingTable ? (
          <LoadingWaitingBox />
        ) : (
          <HistoryTableStyled>
            <TableContainer
              style={{ height: '60vh', width: '100%' }}
              component={Paper}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 700 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No.</StyledTableCell>
                    <StyledTableCell align="right">Request ID</StyledTableCell>
                    <StyledTableCell align="right">Title</StyledTableCell>
                    <StyledTableCell align="right">
                      Creation Time
                    </StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestSupportArr ? (
                    requestSupportArr.map((row, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <StyledTableCell component="th" scope="row">
                          {rowIndex + 1}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.title}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.createdDateTime
                            ? moment(row.createdDateTime).format(
                                'DD-MM-YYYY HH:MM:ss',
                              )
                            : ''}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.status}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <NoDataBox />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </HistoryTableStyled>
        )}
        <div className="pagination">
          <Pagination
            disabled={!configTable.totalPages}
            onChange={onChangePagination}
            count={configTable.totalPages}
            shape="rounded"
            color={'greyPagination'}
          />
        </div>
      </SupportWrapperStyled>
    </WrapperStyled>
  )
}

export default Support
