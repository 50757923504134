/* eslint-disable no-case-declarations */
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

import OverviewService from '@/services/overviewService'
import { useAddToast } from '@/state/application/hooks'
import { OverViewDataChartType } from '@/constants/api'
import moment from 'moment'
// import { CircularProgress, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SkeletonContainer from '@/components/SkeletonContainer'

// const data = [
//   {
//     data: '',
//     value: 0,
//   },
//   {
//     date: '12/6',
//     value: 10,
//   },
//   {
//     date: '13/6',
//     value: 20,
//   },
//   {
//     date: '14/6',
//     value: 50,
//   },
//   {
//     date: '15/6',
//     value: 100,
//   },
//   {
//     date: '16/6',
//     value: 150,
//   },
//   {
//     date: '17/6',
//     value: 160,
//   },
//   {
//     date: '18/6',
//     value: 180,
//   },
//   {
//     date: '19/6',
//     value: 180,
//   },
// ]

enum TimeFrame {
  D1 = '1D',
  W1 = '1W',
  M1 = '1M',
  ALL = 'All',
}

const CustomTooltipStyled = styled.div`
  background-color: #202226;
  width: 50px;
  height: 30px;
  border-radius: 6px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OverviewChartStyled = styled.section`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 899px) {
    padding: 1.5em;
    width: unset;
    margin: 0 0px 0px 0px;
  }

  & > h1 {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .y-tag,
  .x-tag {
    font-size: 15px;
    margin-top: 20px;
    font-weight: 400;
    color: #202226;
  }

  .x-tag {
    text-align: center;
    margin-top: 50px;
  }

  .recharts-responsive-container {
    position: absolute;
    /* left: -15%; */

    @media only screen and (max-width: 576px) {
      position: absolute;
      left: -12%;
    }

    @media only screen and (min-width: 577px) {
      position: absolute;
      left: -5%;
    }

    @media only screen and (min-width: 899px) {
      position: absolute;
      left: -5%;
    }

    @media only screen and (min-width: 1200px) {
      position: relative;
      left: 0;
    }
  }
`

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: 'flex-end';

  & > h1 {
    max-width: 50%;
  }

  @media only screen and (max-width: 899px) {
    flex-direction: column;

    & > h1 {
      font-size: 20px;
    }
  }
`

// const DayChartPicker = styled.div`
//   margin-top: 10px;
//   margin-right: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #e8eaed;
//   height: 40px;
//   padding: 1px 7px;
//   border-radius: 8px;
//   gap: 3px;

//   @media only screen and (max-width: 576px) {
//     margin-right: 0px;
//     margin-top: 0px;
//     align-self: flex-start;
//   }

//   .timeFrameChoosenDiv {
//     padding: 6px;
//     background-color: #e8eaed;
//     border-radius: 6px;
//     background-color: white;
//     cursor: pointer;

//     & > span {
//       font-size: 14px;
//       color: black;

//       @media only screen and (max-width: 576px) {
//         font-size: 12px;
//       }
//     }
//   }
//   & > div {
//     padding: 6px;
//     background-color: #e8eaed;
//     border-radius: 6px;
//     &:hover {
//       background-color: white;
//       cursor: pointer;

//       & > span {
//         font-size: 14px;
//         color: black;
//         @media only screen and (max-width: 576px) {
//           font-size: 12px;
//         }
//       }
//     }

//     & > span {
//       font-size: 14px;
//       color: #a1a9b5;

//       @media only screen and (max-width: 576px) {
//         font-size: 12px;
//       }
//     }
//   }
// `

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipStyled className="custom-tooltip">
        <p className="label">{`${payload[0].value}`}</p>
      </CustomTooltipStyled>
    )
  }

  return null
}

const OverviewChart: FC = () => {
  const addToast = useAddToast()
  const { t } = useTranslation()

  const [chartData, setChartData] = useState<
    OverViewDataChartType[] | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [timeFrameChoosen, setTimeFrameChoosen] = useState(TimeFrame.D1)

  const getStartDate = () => {
    let startDate

    const localTimeCurrentDay = moment()
      .utc()
      .format('YYYY-MM-DD HH:MM:ss')
      .toString()

    const endDate = localTimeCurrentDay

    switch (timeFrameChoosen) {
      case '1D':
        const localTimeFromStartDay = moment().startOf('day').format()
        const localYearStartDay = moment(localTimeFromStartDay).get('year')
        const localMonthIndexStartDay = moment(localTimeFromStartDay).get(
          'month',
        )
        const localDateStartDay = moment(localTimeFromStartDay).get('date')
        const localHourStartDay = moment(localTimeFromStartDay).get('hour')
        const utcDateUnixStartDay = Date.UTC(
          localYearStartDay,
          localMonthIndexStartDay,
          localDateStartDay,
          localHourStartDay,
        )
        startDate = moment(utcDateUnixStartDay)
          .utc()
          .format('YYYY-MM-DD HH:MM:ss')
          .toString()
        break
      case '1W':
        const localTimeFromStartWeek = moment().startOf('week').format()
        const localYearStartWeek = moment(localTimeFromStartWeek).get('year')
        const localWeekIndexStartWeek = moment(localTimeFromStartWeek).get(
          'month',
        )
        const localDateStartWeek = moment(localTimeFromStartWeek).get('date')
        const localHourStartWeek = moment(localTimeFromStartWeek).get('hour')
        const utcDateUnixStartWeek = Date.UTC(
          localYearStartWeek,
          localWeekIndexStartWeek,
          localDateStartWeek,
          localHourStartWeek,
        )
        startDate = moment(utcDateUnixStartWeek)
          .utc()
          .format('YYYY-MM-DD HH:MM:ss')
          .toString()

        break
      case '1M':
        const localTimeFromStartMonth = moment().startOf('month').format()
        const localYearStartMonth = moment(localTimeFromStartMonth).get('year')
        const localMonthIndexStartMonth = moment(localTimeFromStartMonth).get(
          'month',
        )
        const localDateStartMonth = moment(localTimeFromStartMonth).get('date')
        const localHourStartMonth = moment(localTimeFromStartMonth).get('hour')
        const utcDateUnixStartMonth = Date.UTC(
          localYearStartMonth,
          localMonthIndexStartMonth,
          localDateStartMonth,
          localHourStartMonth,
        )
        startDate = moment(utcDateUnixStartMonth)
          .utc()
          .format('YYYY-MM-DD HH:MM:ss')
          .toString()

        break
      case 'All':
        const localTimeFromStartYear = moment().startOf('month').format()
        const localYearStartYear = moment(localTimeFromStartYear).get('year')
        const localMonthIndexStartYear = moment(localTimeFromStartYear).get(
          'month',
        )
        const localDateStartYear = moment(localTimeFromStartYear).get('date')
        const localHourStartYear = moment(localTimeFromStartYear).get('hour')
        const utcDateUnixStartYear = Date.UTC(
          localYearStartYear,
          localMonthIndexStartYear,
          localDateStartYear,
          localHourStartYear,
        )
        startDate = moment(utcDateUnixStartYear)
          .utc()
          .format('YYYY-MM-DD HH:MM:SS')
          .toString()
        break
    }

    return {
      startDate,
      endDate,
    }
  }

  const getChartApi = async (TimeFrameProps = 'D') => {
    setIsLoading(true)
    const { startDate, endDate } = getStartDate()
    const params = {
      TimeFrame: TimeFrameProps,
      StartDate: startDate,
      EndDate: endDate,
    }
    const overviewRes = await OverviewService.getChartApi(params)
    if (overviewRes && overviewRes.length >= 0) {
      if (timeFrameChoosen === '1D') {
        const chartDataTemp = [
          {
            date: '',
            value: 0,
          },
          ...overviewRes,
        ]
        setChartData(chartDataTemp)
      } else {
        setChartData(overviewRes)
      }
    } else {
      addToast({
        msg: 'Can not get overview chart data or there is no data.',
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getChartApi('D')
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onHandleClickTimeFrame = (typeTimeFrame: TimeFrame) => {
    setTimeFrameChoosen(typeTimeFrame)
    let timeFrameConverted
    switch (typeTimeFrame) {
      case '1D':
        timeFrameConverted = 'D'
        break
      case '1W':
        timeFrameConverted = 'W'
        break
      case '1M':
        timeFrameConverted = 'M'
        break
      case 'All':
        timeFrameConverted = 'A'
        break
    }

    getChartApi(timeFrameConverted)
  }

  return (
    <OverviewChartStyled>
      <TopContainer>
        <h1>{t('overview.recruitHistory')}</h1>
        {/* <DayChartPicker>
          <div
            className={
              timeFrameChoosen === TimeFrame.D1 ? 'timeFrameChoosenDiv' : ''
            }
            onClick={() => onHandleClickTimeFrame(TimeFrame.D1)}
          >
            <span>{TimeFrame.D1}</span>
          </div>
          <div
            className={
              timeFrameChoosen === TimeFrame.W1 ? 'timeFrameChoosenDiv' : ''
            }
            onClick={() => onHandleClickTimeFrame(TimeFrame.W1)}
          >
            <span>{TimeFrame.W1}</span>
          </div>
          <div
            className={
              timeFrameChoosen === TimeFrame.M1 ? 'timeFrameChoosenDiv' : ''
            }
            onClick={() => onHandleClickTimeFrame(TimeFrame.M1)}
          >
            <span>{TimeFrame.M1}</span>
          </div>
          <div
            className={
              timeFrameChoosen === TimeFrame.ALL ? 'timeFrameChoosenDiv' : ''
            }
            onClick={() => onHandleClickTimeFrame(TimeFrame.ALL)}
          >
            <span>{t(TimeFrame.ALL)}</span>
          </div>
        </DayChartPicker> */}
      </TopContainer>
      <SkeletonContainer isLoading={isLoading}>
        <>
          <div style={{ width: '100%', height: 300, position: 'relative' }}>
            <h2 className="y-tag">{t('overview.validUser')}</h2>
            <ResponsiveContainer>
              <AreaChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0.31%" stopColor="rgba(255, 122, 0, 0.2)" />
                    <stop offset="98.63%" stopColor="rgba(255, 254, 254, 0)" />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} horizontal />
                <XAxis dataKey="date" />
                <YAxis
                  domain={[
                    0,
                    (dataMax: number) => Math.round((dataMax * 1.1) / 10) * 10,
                  ]}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#FF821C"
                  fill="url(#colorValue)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <h2 className="x-tag">
            {(!chartData || chartData.length === 0) && !isLoading
              ? t('noData')
              : t('time')}
          </h2>
        </>
      </SkeletonContainer>
    </OverviewChartStyled>
  )
}

export default OverviewChart
