/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress, Skeleton } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'

interface ButtonProps {
  isLoading?: boolean
  className?: string
  disabled?: boolean
  onClick?: any
  styleCss?: any
  classCss?: any
  title?: string
  isPrimaryButton?: boolean
}

const ButtonContainerStyled = styled.div`
  transition: all 1s;
  position: relative;
  display: flex;
  /* flex-direction: column; */

  & > button {
    &:hover {
      cursor: pointer;
      opacity: 0.7 !important;
    }
  }

  .defailtButtonPrimaryClass {
    width: 100%;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    min-width: 145px;
    color: white;
    border-radius: 6px;
    font-weight: 500;
    outline: none;
    border: 1px solid #298cfd;
    font-size: 16px;
    background-color: #298cfd;
    cursor: pointer;
    &:disabled {
      opacity: 0.6 !important;
      background-color: #98c4fa;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover {
      background-color: #afd4ff;
      cursor: pointer;
    }
  }

  .defailtButtonClass {
    width: 100%;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    min-width: 145px;
    color: #000000;
    border-radius: 6px;
    outline: none;
    border: 1px solid gray;
    font-size: 16px;
    background-color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      opacity: 0.6 !important;
      background-color: #98c4fa;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover {
      background-color: #dfdfdf;
      cursor: pointer;
    }
  }
`

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const {
    isLoading,
    className,
    disabled = false,
    onClick,
    styleCss,
    classCss,
    title = '',
    isPrimaryButton = true,
  } = props
  return (
    <ButtonContainerStyled {...(className ? { className: className } : null)}>
      <button
        {...(styleCss
          ? { style: { ...styleCss, opacity: isLoading ? 0.5 : 1 } }
          : { style: { opacity: isLoading ? 0.5 : 1 } })}
        {...(classCss
          ? { className: classCss }
          : {
              className: isPrimaryButton
                ? 'defailtButtonPrimaryClass'
                : 'defailtButtonClass',
            })}
        {...props}
        disabled={disabled}
        onClick={onClick}
      >
        {isLoading ? <CircularProgress size={18} color="inherit" /> : title}
      </button>
    </ButtonContainerStyled>
  )
}

export default Button
