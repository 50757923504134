import { CircularProgress, Skeleton } from '@mui/material'
import styled from 'styled-components'
// import { IoSettingsOutline } from 'react-icons/io5'
import { VscChevronRight } from 'react-icons/vsc'
import { HiCurrencyDollar, HiUserGroup } from 'react-icons/hi'
import { BsPersonCheck } from 'react-icons/bs'
import { useEffect, useState } from 'react'

import copyIcon from '@/assets/icon-copy.svg'
import { useAddToast } from '@/state/application/hooks'
import { copyToClipboard } from '@/utils'
import OverviewService from '@/services/overviewService'

import OverviewChart from './OverviewChart'
import OverViewHistory from './OverViewHistory'
import { OverViewDataType } from '@/constants/api'
import InformationIcon from '@/components/InformationIcon'
import { useAuth } from '@/context/AuthProvider'
import UserService from '@/services/userService'
import { useNavigate } from 'react-router'
import { DefaultRoute } from '@/constants/types'
import { useTranslation } from 'react-i18next'

const WrapperStyled = styled.div`
  width: 100%;
  /* min-width: 600px; */

  @media (max-width: 576px) {
    width: 100%;
    min-width: 300px;
    position: relative;
    overflow: scroll;
  }
`

const ShareLinkStyled = styled.div`
  background-color: white;
  padding: 30px 40px;
  border-radius: 10px;
  min-height: 220px;
  position: relative;

  p {
    line-height: 1.5;
  }

  @media (max-width: 899px) {
    padding: 1.5em;
    margin: 0;
  }

  .get-link {
    display: flex;
    align-items: center;

    h4 {
      font-size: 1.125rem;
      font-weight: 500;
      color: #202226;
      margin-right: 20px;
      white-space: nowrap;

      @media (max-width: 576px) {
        align-self: flex-start;
      }
    }

    .linkBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 576px) {
        justify-content: space-between;
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  .btn {
    min-height: 20px;
    padding: 9.5px 0 6.5px 15px;
    width: 100%;
    max-width: 390px;
    border: 1px solid #c9ced6;
    border-radius: 8px;
    color: #202226;
    margin-right: 5px;
  }

  .copy {
    background-color: #298cfd;
    border-radius: 6px;
    padding: 8px;
    margin-right: 25px;
    height: 22px;
    width: 22px;

    @media (max-width: 576px) {
      margin-right: 0px;
    }

    &:hover {
      cursor: pointer;
      background: #0075fb;
    }
  }

  .icon {
    background-color: #e8eaed;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    &:hover {
      background-color: #dcdee2;
      cursor: pointer;
    }
  }

  p {
    color: #32373d;
  }

  .view {
    display: flex;
    align-items: center;
    color: #298cfd;
    gap: 5px;
    align-self: flex-start;
    width: fit-content;
    transition: color 300ms ease;
    font-weight: 500;

    & > span {
      &:hover {
        cursor: pointer;
        color: #0260cc;
      }
    }
  }
`

const ButtonRegister = styled.div`
  align-self: center;
  margin: auto;
  margin: 2em 0;
  background-color: #ff821c;
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: background-color 300ms ease;
  > span {
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: #f2681d;
  }
`
const OverViewStyled = styled.div`
  .overview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      margin: 0 0 0 15px;
    }

    & > h1 {
      font-size: 1.875rem;
      font-weight: 500;
      line-height: 1.4;
    }
  }
`

const OverviewCountStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: 'space-between';
  position: relative;

  @media (max-width: 899px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 900px) {
    gap: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 1200px) {
    gap: 30px;
    margin-bottom: 30px;
  }

  .overview-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2vh 2vw;
    background-color: white;
    border-radius: 10px;

    @media (max-width: 899px) {
      width: unset;
      padding: 1.5em;
    }

    & > p {
      font-size: 3.75rem;
      line-height: 1.4;
      font-weight: 500;
      margin: 0;

      @media (max-width: 899px) {
        font-size: 3rem;
      }
    }
  }

  .overview-item__header {
    flex: 1;
    align-self: 'top';
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    & > p {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .label-overview__header {
    font-size: 18px;
    font-weight: 500;
    color: black;
  }

  .number-overview__header {
    color: black;
    font-size: 60px;
  }

  .header--icon-box {
    display: flex;
    justify-content: 'center';
    align-items: 'center';
    padding: 7px;
    background-color: #fff3e9;
    border-radius: 100%;
  }

  .header--icon {
    aspect-ratio: 1 / 1;
    color: #ff821c;
  }
`

export function Overview() {
  const addToast = useAddToast()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user, login } = useAuth()
  const [isLoadingApi, setIsLoadingApi] = useState<boolean>(true)
  const [isLoadingRegister, setIsLoadingRegister] = useState<boolean>(false)
  const [lastestCode, setLastestCode] = useState<string>('')
  const [overviewData, setOverviewData] = useState<OverViewDataType>({
    totalRecruited: undefined,
    toTalValid: undefined,
    totalEarned: undefined,
  })
  const hasReferralCode = user ? user.hasReferralCode : false

  const fetchAllApi = async () => {
    setIsLoadingApi(true)
    const [lastestCodeRes, overviewRes] = await Promise.all([
      OverviewService.getLastestCode(),
      OverviewService.getOverViewData(),
    ])

    if (lastestCodeRes && lastestCodeRes.data) {
      setLastestCode(lastestCodeRes.data)
    } else {
      addToast({
        msg: lastestCodeRes?.message || 'Can not get lastest code!',
      })
    }

    if (overviewRes && overviewRes.data) {
      setOverviewData({
        toTalValid: overviewRes.data.toTalValid,
        totalEarned: overviewRes.data.totalEarned,
        totalRecruited: overviewRes.data.totalRecruited,
      })
    } else {
      addToast({
        msg: overviewRes?.message || 'Can not get overview data!',
      })
    }

    setIsLoadingApi(false)
  }

  useEffect(() => {
    if (hasReferralCode) {
      fetchAllApi()
    }
  }, [])

  const onHandleRegisterUser = async () => {
    if (isLoadingRegister) {
      return
    }
    setIsLoadingRegister(true)
    const registerRes = await UserService.postRegisterUser()
    if (registerRes && registerRes.message) {
      addToast({
        msg: registerRes.message,
      })
    }

    if (registerRes && registerRes.success) {
      await fetchAllApi()
      login({ redirectIfFail: false })
      addToast({
        msg: 'Register successfully!',
      })
    } else {
      //
    }

    setIsLoadingRegister(false)
  }

  const onHandleCopyLink = () => {
    copyToClipboard(`https://herond.org?ref=${lastestCode}`)
    addToast({
      msg: 'Copied',
    })
  }

  interface PropsOverviewBoxType {
    icon: JSX.Element
    title: string
    value: string | number | undefined
    informationTitle: string
    isLoading: boolean
  }

  const OverviewBox = (propsOverviewBox: PropsOverviewBoxType) => {
    const { icon, title, value, informationTitle, isLoading } = propsOverviewBox
    return (
      <div className="overview-item">
        <div className="overview-item__header">
          <div className="header--icon-box">{icon}</div>
          <p className="label-overview__header">{title}</p>
          <InformationIcon>{informationTitle}</InformationIcon>
        </div>
        {isLoading ? (
          <div className="loadingBox">
            <Skeleton
              variant="text"
              sx={{
                fontSize: '60px',
                height: '84px',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          </div>
        ) : (
          <p className="number-overview__header">{value}</p>
        )}
      </div>
    )
  }

  const onHandleViewDetail = () => {
    navigate(DefaultRoute.LinkManageMent)
  }

  const onHandleViewPolicy = () => {
    navigate(DefaultRoute.Policy)
  }

  return (
    <WrapperStyled>
      {hasReferralCode ? (
        <>
          <ShareLinkStyled>
            <div className="get-link">
              <h4>{t('overview.shareLinkAndGetRewards')}</h4>
              {/* <div className="btn">https://herond.org/clickme</div> */}
              <div className="linkBox">
                {isLoadingApi ? (
                  <Skeleton
                    variant="text"
                    className="btn"
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      fontSize: '40px',
                    }}
                  />
                ) : (
                  <div className="btn">
                    https://herond.org?ref={lastestCode}
                  </div>
                )}
                <img
                  onClick={onHandleCopyLink}
                  src={copyIcon}
                  alt="copy"
                  className="copy"
                />
              </div>
              {/* <div className="icon">+</div>
                <IoSettingsOutline width={20} height={20} className="icon" /> */}
            </div>
            <p>{t('overview.unlimitedGrowthOpportunities')}</p>
            <div className="view">
              <span onClick={onHandleViewDetail}>
                {t('overview.viewDetail')}
              </span>
              <VscChevronRight />
            </div>
          </ShareLinkStyled>
          <OverViewStyled>
            <div className="overview-header">
              <h1>{t('overview.overview')}</h1>
            </div>
            <OverviewCountStyled>
              <OverviewBox
                icon={<HiUserGroup size={24} className="header--icon" />}
                title={t('overview.recruitedUser')}
                value={overviewData.totalRecruited}
                informationTitle={t('overview.theTotalAmountOfUsers')}
                isLoading={isLoadingApi}
              />
              <OverviewBox
                icon={<BsPersonCheck size={24} className="header--icon" />}
                title={t('overview.validUser')}
                value={overviewData.toTalValid}
                informationTitle={t(
                  'overview.theTotalAmountEligibilityStandards',
                )}
                isLoading={isLoadingApi}
              />
              <OverviewBox
                icon={<HiCurrencyDollar size={24} className="header--icon" />}
                title={`${t('overview.earnMoney')} (USDT)`}
                value={overviewData.totalEarned}
                informationTitle={t('overview.theTotalAmountMoney')}
                isLoading={isLoadingApi}
              />
            </OverviewCountStyled>
            <OverViewHistory />
            <OverviewChart />
          </OverViewStyled>
        </>
      ) : (
        <ShareLinkStyled>
          <div className="get-link">
            <h4>{t('overview.shareLinkAndGetRewards')}</h4>
            {/* <div className="btn">https://herond.org/clickme</div> */}
            <Skeleton
              variant="text"
              className="btn"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                fontSize: '40px',
              }}
            />
            {/* <div className="icon">+</div>
            <IoSettingsOutline width={20} height={20} className="icon" /> */}
          </div>
          <span>{t('overview.becomeAPartOfOurAffiliate')}</span>

          <ButtonRegister
            aria-disabled={isLoadingRegister}
            onClick={onHandleRegisterUser}
          >
            {isLoadingRegister && (
              <CircularProgress
                style={{ marginRight: 10 }}
                size={15}
                color="inherit"
              />
            )}
            <span>{t('register')}</span>
          </ButtonRegister>

          <div className="view" onClick={onHandleViewPolicy}>
            <span>{t('readPolicyDocument')}</span>
            <VscChevronRight />
          </div>
        </ShareLinkStyled>
      )}
    </WrapperStyled>
  )
}

export default Overview
