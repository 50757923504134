import Button from '@/components/Button'
import useModel from '@/context/ModelContext'
// import LinkManagementService from '@/services/linkManagementService'
// import { useAddToast } from '@/state/application/hooks'
import images from '@/utils/images'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// import Button from '@/components/Button'

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &:hover {
    cursor: pointer;
    background-color: #e4e4e4;
    border-radius: 20%;
  }
`

const DeleteModalStyled = styled.div`
  position: fixed;
  z-index: 100;
  padding: 30px;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  width: 350px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .buttonBox {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 576px) {
    width: calc(100vw - 90px);
  }
`

const ConfirmText = styled.span`
  font-size: 16px;
  margin-top: 1em;
  margin-bottom: 2em;
`

interface PropsType {
  referralCode: string
  callBackAfterDeleteDone?: VoidFunction
  onHandleDeleteCallback?: VoidFunction
}

export const DeleteLinkPopup = (props: PropsType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { referralCode, callBackAfterDeleteDone, onHandleDeleteCallback } =
    props
  const [isLoading, setIsLoading] = useState(false)
  const useModal = useModel()
  const { t } = useTranslation()
  // const addToast = useAddToast()
  const hideModal = () => {
    useModal.setModelContent(undefined)
    useModal.hideModal()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onHandleDeleteLink = async () => {
    setIsLoading(true)
    if (onHandleDeleteCallback !== undefined) {
      onHandleDeleteCallback()
    }
    // setIsLoading(true)
    // const linkRes = await LinkManagementService.postDeleteLinkAffiliateData(
    //   referralCode,
    // )

    // if (linkRes && linkRes.message) {
    //   addToast({
    //     msg: linkRes.message,
    //   })
    // }

    // if (linkRes && linkRes.success && callBackAfterDeleteDone !== undefined) {
    //   callBackAfterDeleteDone()
    // } else {
    //   hideModal()
    //   setIsLoading(false)
    // }
  }

  return (
    <DeleteModalStyled onClick={(e) => e.preventDefault()}>
      <CloseButton onClick={hideModal}>
        <img
          src={images.closeButtonIcon}
          alt=""
          style={{ width: '20px', height: '20px' }}
        />
      </CloseButton>
      <ConfirmText>
        {t('linkList.pleaseConfirmToDeleteReferralCode')}: {referralCode}
      </ConfirmText>
      <div className="buttonBox">
        <Button
          isPrimaryButton={false}
          onClick={hideModal}
          title={t('no') || 'No'}
        />
        <Button
          disabled={isLoading}
          onClick={onHandleDeleteLink}
          isLoading={isLoading}
          title={t('yes') || 'Yes'}
        />
      </div>
    </DeleteModalStyled>
  )
}
