import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import imgHerondLogoDark from '@/assets/herond-logo-dark.svg'
import imgHerondLogoLight from '@/assets/herond-logo-light.svg'

// import LanguageSwitcher from './LanguageSwitcher'
// import EarningButton from './EarningButton'
// import Image from './Image'
// import images from '@/utils/images'
// import { useAuth } from '@/context/AuthProvider'
// import Hamburger from './Hambuger'
// import BrowserDownloadButton from './BrowserDownloadButton'
// import { Drawer } from '@mui/material'
// import config, { NavLink } from '@/config'
// import ScrollLink from './ScrollLink'
// import { useTranslation } from 'react-i18next'
// import { DefaultRoute } from '@/constants/types'

interface HeaderProps {
  themeDefault: 'dark' | 'light'
}

const StyledHeaderWrapper = styled.div`
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 300ms ease;
  &.hide {
    transform: translateY(-100px);
  }
  &.light {
    border-bottom: 1px solid #ffffff;
    background-color: #ffffff;
    color: #7a818c;
    box-shadow: 0px 1px 0px 0px #7a818c33;
    .active {
      color: #32373d;
    }
  }
  &.dark {
    border-bottom: 1px solid #1d212b;
    background-color: #1d212b;
    color: #c9ced6;
    box-shadow: none;
    .active {
      color: #ffffff;
    }
  }
`

const StyledHeader = styled.div`
  height: 70px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;

  @media only screen and (max-width: 576px) {
    padding-left: 10px;
    padding-right: 0;
  }
`

const StyledHeaderLeftSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease;
  text-decoration: none;
  color: inherit;
`

const LogoStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  img {
    height: 47px;

    @media only screen and (max-width: 576px) {
      height: 27px;
    }
  }

  & > div {
    font-size: 23px;
    color: #7a818c;
    margin-top: 5px;

    @media only screen and (max-width: 576px) {
      font-size: 16px;
      margin-top: 0px;
    }
  }
`

// const StyledHeaderRightSection = styled.div`
//   text-decoration: none;
//   min-width: 200px;
//   display: flex;
//   justify-content: end;
//   align-items: center;
//   text-transform: capitalize;
//   transition: all 500ms ease;
//   svg {
//     transition: all 500ms ease;
//     margin: 0 8px 0 5px;
//   }

//   @media only screen and (max-width: 900px) {
//     margin-right: 10px;
//   }
// `

// const AvatarWrapper = styled.div`
//   display: flex;
// `

// const AvatarStyled = styled.div`
//   position: relative;
//   cursor: pointer;
//   display: flex;
//   align-items: center;

//   img {
//     height: 40px;
//     aspect-ratio: 1;

//     @media only screen and (max-width: 900px) {
//       height: 30px;
//     }
//   }

//   .ava-group {
//     min-width: 200px;
//     position: absolute;
//     z-index: 10;
//     display: block;
//     color: black;
//     font-size: 14px;
//     top: 100%;
//     right: 0;
//     background: white;
//     padding: 20px 0;
//     border: 1px solid #dce0e3;
//     border-radius: 6px;
//     display: none;
//     cursor: pointer;

//     &.show {
//       display: block;
//     }
//   }

//   .ava-item {
//     padding: 10px 50px 10px 20px;
//     transition: all 500ms ease;
//     &:hover {
//       background-color: #fff3e9;
//     }
//   }
// `

// const StyledDrawer = styled(Drawer)`
//   .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorTop {
//     background: unset;
//   }
// `

// const StyledDrawerWrapper = styled(Box)`
//   overflow: scroll;
//   width: auto;
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   align-items: center;
//   min-height: calc(100vh - 70px);
//   background-color: white;
//   padding: 0 22px;
//   padding-bottom: 70px;
//   .navLink {
//     margin-top: 100px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     width: 100%;
//     a {
//       padding: 14px 0;
//       font-size: 18px;
//       margin: 0;
//     }
//   }

//   .lineBox {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin: 1.5em 0 1.5em 0;

//     .line1 {
//       flex: auto;
//       width: 100%;
//       height: 1px;
//       background-color: #f9be4f;
//     }

//     span {
//       color: #696868;
//       margin: 0 10px;
//       font-weight: 500;
//     }

//     .line2 {
//       flex: auto;
//       width: 100%;
//       height: 1px;
//       background-color: #f7c872;
//     }
//   }

//   .link-button {
//     width: 100%;
//   }
// `

// const StyledMenuToggle = styled.div`
//   margin-left: 1em;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   @media only screen and (min-width: 900px) {
//     display: none;
//   }
// `

// const StyledNavLinkWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   height: 100%;
//   max-height: 32px;
//   font-size: 1em;

//   @media only screen and (max-width: 900px) {
//     display: none;
//   }

//   .headerLink {
//     height: 70px;
//     text-decoration: none;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0 0.5em;
//     text-transform: capitalize;
//     transition: all 1ms ease;
//     font-size: 0.65em;
//     font-weight: 500;
//     color: #7a818c;
//     min-width: 100px;
//     border-bottom: 1px solid transparent;

//     &:hover {
//       cursor: pointer;
//       border-bottom: 1px solid orange;
//     }
//     @media only screen and (max-width: 576px) {
//       width: 100%;
//       font-weight: 400;
//       line-height: 1.5;
//       text-transform: capitalize;
//       height: unset;
//       text-align: center;
//       margin-bottom: 5px;
//     }
//   }
// `

// const NavbarItem = styled(Link)`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   padding: 15px 15px 15px 15px !important;
//   gap: 20px;
//   margin-bottom: 10px;
//   text-decoration: none;
//   box-sizing: border-box;
//   border-radius: 6px;
//   color: #7a818c;
//   &:hover {
//     background-color: #ffa55c;
//     color: white;
//     cursor: pointer;
//   }

//   &.active {
//     background-color: #ff821c;
//     color: white;
//   }
// `

const Header: React.FC<HeaderProps> = ({ themeDefault }) => {
  const navigate = useNavigate()
  const [lastOffset, setLastOffset] = useState<number>(0)
  // const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false)
  // const { t } = useTranslation()

  const [theme, setTheme] = useState<'dark' | 'light'>(themeDefault)
  // const { user } = useAuth()
  // const location = useLocation()
  // const isHomePage = location.pathname === DefaultRoute.Homepage

  const [openLogout, setOpenLogOut] = useState<boolean>(false)
  const avatarRef = useRef<HTMLDivElement>(null)
  // const isAdminRole = user?.isAdmin || false
  // const onHandleManageAccount = () => {
  //   window.open('https://accounts.herond.org/', '_blank')
  // }

  useEffect(() => {
    if (!openLogout) return
    const handleClick = (e: MouseEvent) => {
      if (avatarRef.current && !avatarRef.current.contains(e.target as Node)) {
        setOpenLogOut(false)
      }
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [openLogout])

  useEffect(() => {
    const colorSection = document.getElementById('color-section')

    const scrollHandler = () => {
      setLastOffset(window.scrollY)

      if (
        window.scrollY <
        (colorSection ? colorSection?.clientHeight : window.innerHeight)
      ) {
        setTheme('dark')
      } else {
        setTheme('light')
      }
    }

    if (themeDefault === 'dark') {
      document.addEventListener('scroll', scrollHandler)
    } else {
      return
    }

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [lastOffset, themeDefault])

  // const handleOnChange = useCallback(() => {
  //   setIsShowDrawer(!isShowDrawer)
  // }, [isShowDrawer])

  // const onOpenLinkUrlHeader = (navLink: NavLink) => {
  //   window.open(navLink.to, '_blank')
  // }

  const onHandleGoToHome = () => {
    navigate('/')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const onOpenLinkDashboard = () => {
  //   setIsShowDrawer(false)
  // }

  return (
    <>
      <StyledHeaderWrapper className={`${theme}`}>
        <StyledHeader>
          <StyledHeaderLeftSection>
            <LogoStyled>
              <img
                onClick={onHandleGoToHome}
                src={theme === 'dark' ? imgHerondLogoLight : imgHerondLogoDark}
                alt="herond logo"
              />
              <div>Affiliate</div>

              {/* <StyledNavLinkWrapper>
                {config.navLink.map((item, itemIndex) => {
                  if (item.isExternal) {
                    return (
                      <Link
                        key={itemIndex}
                        className="headerLink"
                        to={item.to || DefaultRoute.EarnReward}
                      >
                        {t(item.title)}
                      </Link>
                    )
                  } else {
                    return (
                      <span
                        key={itemIndex}
                        onClick={() => onOpenLinkUrlHeader(item)}
                        className="headerLink"
                      >
                        {t(item.title)}
                      </span>
                    )
                  }
                })}
              </StyledNavLinkWrapper> */}
            </LogoStyled>
          </StyledHeaderLeftSection>
          {/* <StyledHeaderRightSection>
            {user && user?.userId ? (
              <AvatarWrapper>
                <LanguageSwitcher theme={theme} />
                <AvatarStyled
                  onClick={() => setOpenLogOut(true)}
                  ref={avatarRef}
                >
                  <Image
                    styleCss={{ borderRadius: '100%' }}
                    isActiveSkeleton
                    defaultSrc={images.defaultAvatar}
                    src={user?.profilePicture}
                    alt="avatar"
                  />
                  <div className={`${openLogout ? 'show' : 'hide'} ava-group`}>
                    <div className="ava-item" onClick={onHandleManageAccount}>
                      {t('manageAccount')}
                    </div>
                  </div>
                </AvatarStyled>
              </AvatarWrapper>
            ) : (
              <>{theme === 'light' ? <EarningButton /> : ''}</>
            )}

            <StyledMenuToggle>
              <Hamburger
                isBlackColor={theme === 'light'}
                isActive={isShowDrawer}
                onChange={setIsShowDrawer}
              />
            </StyledMenuToggle>
          </StyledHeaderRightSection> */}
        </StyledHeader>
      </StyledHeaderWrapper>

      {/* <StyledDrawer
        anchor="top"
        open={isShowDrawer}
        onClose={handleOnChange}
        style={{ zIndex: 99 }}
      >
        <StyledDrawerWrapper role="presentation">
          <div className="navLink">
            {isHomePage ? (
              config.navLink.map((item) => (
                <ScrollLink
                  key={item.title}
                  isExternal={item.isExternal}
                  to={item.to || '/'}
                  isActive={item.to === '/earn-reward'}
                  // isActive={activeNav === item.to}
                  functionToggle={setIsShowDrawer}
                >
                  {t(item.title)}
                </ScrollLink>
              ))
            ) : (
              <>
                {config.dashboardNavLink.map((nav) => (
                  <NavbarItem
                    onClick={onOpenLinkDashboard}
                    to={nav.to || DefaultRoute.Dashboard}
                    key={nav.title}
                    // className={`${activeNav === nav.to ? 'active' : ''}`}
                  >
                    {nav.icon}
                    <div className="title">{t(nav.title)}</div>
                  </NavbarItem>
                ))}
                {isAdminRole && (
                  <>
                    <div className="lineBox">
                      <div className="line1" />
                      <span>Admin</span>
                      <div className="line2" />
                    </div>
                    {config.adminNavLink.map((nav) => (
                      <NavbarItem
                        onClick={onOpenLinkDashboard}
                        to={nav.to || DefaultRoute.Dashboard}
                        key={nav.title}
                      >
                        {nav.icon}
                        <div className="title">{t(nav.title)}</div>
                      </NavbarItem>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          <BrowserDownloadButton />
        </StyledDrawerWrapper>
      </StyledDrawer> */}
    </>
  )
}

export default Header
