import React from 'react'
import styled from 'styled-components'

import banner from '@/assets/banner.png'

import Lang from '@/components/Lang'
import BrowserDownloadButton from '@/components/BrowserDownloadButton'
import herondLogo from '@/assets/herond-logo-icon.svg'

const StyledTitle = styled.div`
  color: white;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1.5;
  margin-bottom: 50px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;
    text-align: center;
    font-size: 22px;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  background: linear-gradient(94deg, #71bbff -7.98%, #015cdb 78.89%);
  position: relative;
  overflow: hidden;

  .line {
    position: absolute;
    z-index: 3;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 102.04%
    );
  }

  .line-1 {
    top: 12%;
    right: 5%;
    width: 26.5em;
    height: 2.5em;
  }

  .line-2 {
    width: 25em;
    height: 2.5em;
    top: 20%;
    left: 5%;
  }

  .line-3 {
    width: 14.5em;
    height: 1.75em;
    top: 45%;
    left: 0%;
  }

  .line-4 {
    width: 16.5em;
    height: 2.65em;
    top: 50%;
    right: 25%;
  }

  @media (max-width: 992px) {
    .line {
      font-size: 12px;
    }

    .line-1 {
      right: -5%;
      width: 20em;
    }

    .line-2 {
      left: -5%;
      width: 15em;
    }

    .line-3 {
      left: -10%;
    }

    .line-4 {
      right: 10%;
      top: 70%;
      width: 20em;
    }
  }

  @media (max-width: 576px) {
    .line {
      font-size: 10px;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  padding-top: 90px;

  .herond-icon {
    margin-bottom: 30px;
  }

  .banner-icon {
    margin-top: 96px;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 1920px) {
    max-width: 80%;
  }

  @media (max-width: 992px) {
    max-width: 100%;
    padding: 0 23px;
    .herond-icon {
      margin-top: 70px;
    }
  }
`

const SectionBanner: React.FC = () => {
  return (
    <StyledContainer>
      <ContentWrapper>
        <img src={herondLogo} alt="herond" className="herond-icon" />

        <Lang
          vi={<StyledTitle>Tải và trải nghiệm Herond Browser!</StyledTitle>}
          en={<StyledTitle>Install Herond Browser now!</StyledTitle>}
        />
        <BrowserDownloadButton />
        <img src={banner} alt="banner" className="banner-icon" />
      </ContentWrapper>

      <div className="rectangle" />
      <div className="line line-1" />
      <div className="line line-2" />
      <div className="line line-3" />
      <div className="line line-4" />
    </StyledContainer>
  )
}

export default SectionBanner
