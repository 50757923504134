import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  vi?: React.ReactNode
  en?: React.ReactNode
}

export const Lang: React.FC<Props> = ({ vi, en }) => {
  const {
    i18n: { language },
  } = useTranslation()
  return <>{language === 'vi' ? vi : en}</>
}

export default Lang
