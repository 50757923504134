import {
  PaymentAPI,
  PaymentOverviewType,
  WithdrawnHistoryTableType,
} from '@/constants/api'
import http from '@/utils/http'
export default class PaymentService {
  static getOverViewData = async () => {
    try {
      const res = await http.get<PaymentOverviewType>(PaymentAPI.overviewData, {
        withCredentials: true,
      })

      if (res.success && res.data) {
        return res.data
      }
    } catch (e) {
      //
    }
    return undefined
  }

  static getHistoryApi = async () => {
    try {
      const res = await http.get<WithdrawnHistoryTableType>(
        PaymentAPI.withdrawalHistory,
        {
          withCredentials: true,
        },
      )

      if (res.success && res.data) {
        return res.data
      }
    } catch (e) {
      //
    }
    return undefined
  }
}
