import styled from '@emotion/styled'
import { AiOutlineTwitter } from 'react-icons/ai'
import { BsDiscord } from 'react-icons/bs'
import { FaTelegramPlane } from 'react-icons/fa'
import { ImFacebook } from 'react-icons/im'

const StyledGroupIcon = styled.div`
  display: flex;
  gap: 25px;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    border-radius: 5px;
    transition: all 0.25s ease;
    color: inherit;
    svg {
      width: 25px;
      height: 25px;
    }

    &.discord:hover {
      color: white;
      background-color: #6972f7;
    }

    &.twitter:hover {
      color: white;
      background-color: #259bdb;
    }

    &.facebook:hover {
      color: white;
      background-color: #297dff;
    }

    &.telegram:hover {
      color: white;
      background-color: #23aaea;
    }
  }

  @media screen and (max-width: 576px) {
    margin: 0;
    gap: 20px;
    .icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`

const GroupSocial = () => {
  return (
    <StyledGroupIcon className="group-social">
      <a
        href="https://discord.gg/v946Pwnu3B"
        target="blank"
        className="icon discord"
      >
        <BsDiscord />
      </a>
      <a
        href="https://twitter.com/HerondBrowser"
        target="blank"
        className="icon twitter"
      >
        <AiOutlineTwitter />
      </a>
      <a
        href="https://www.facebook.com/HerondBrowser"
        target="blank"
        className="icon facebook"
      >
        <ImFacebook />
      </a>
      <a
        href="https://t.me/herond_browser"
        target="blank"
        className="icon telegram"
      >
        <FaTelegramPlane />
      </a>
    </StyledGroupIcon>
  )
}

export default GroupSocial
