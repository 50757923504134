import images from '@/utils/images'
import { Skeleton } from '@mui/material'
import { FC, useState } from 'react'
import styled from 'styled-components'

const ImageBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 20px;
  min-height: 20px;
`

interface PropsType {
  src: string
  alt?: string
  defaultSrc?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleCss?: any
  width?: number | string
  height?: number | string
  isActiveSkeleton?: boolean
}

const Image: FC<PropsType> = (props: PropsType) => {
  const [isLoadingImg, setIsLoadingImg] = useState<boolean>(false)
  const {
    src,
    alt,
    defaultSrc,
    styleCss,
    width,
    height,
    isActiveSkeleton = false,
  } = props

  const onHandleLoadingImg = () => {
    setIsLoadingImg(false)
  }
  return (
    <ImageBoxStyled>
      {isActiveSkeleton && (
        <Skeleton
          style={
            isLoadingImg
              ? {
                  zIndex: 100,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }
              : {
                  zIndex: 100,
                  display: 'none',
                }
          }
          sx={{
            transform: 'scale(1)',
          }}
        />
      )}
      <img
        onLoad={onHandleLoadingImg}
        {...(styleCss ? { style: styleCss } : null)}
        {...(width ? { width: width } : null)}
        {...(height ? { height: height } : null)}
        {...props}
        loading="lazy"
        src={src}
        alt={alt}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = defaultSrc || images.defaultAvatar
        }}
      />
    </ImageBoxStyled>
  )
}

export default Image
