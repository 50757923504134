import { DefaultRoute } from '@/constants/types'
import { authRoutes } from '@/routers'
import {
  createContext,
  useState,
  useEffect,
  useCallback,
  FC,
  PropsWithChildren,
  useContext,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface BrowserCheckContextType {
  isHerondBrowser: boolean | undefined
}

const defaultValue: BrowserCheckContextType = {
  isHerondBrowser: undefined,
}

export const BrowserCheckContext =
  createContext<BrowserCheckContextType>(defaultValue)

export const useIsHerondBrowser = () => {
  return useContext(BrowserCheckContext).isHerondBrowser
}

export const BrowserCheckProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isHerondBrowser, setIsHerondBrowser] = useState<boolean | undefined>(
    defaultValue.isHerondBrowser,
  )

  const checkHerond = useCallback(async () => {
    try {
      setIsHerondBrowser(await navigator.herond.isHerond())
    } catch (e) {
      setIsHerondBrowser(false)
    }
  }, [])

  useEffect(() => {
    checkHerond()
  }, [])

  useEffect(() => {
    const isAuthRoute = authRoutes.find(
      (x) => x.toString() === location.pathname,
    )
    if (isAuthRoute && isHerondBrowser === false) {
      navigate(DefaultRoute.Homepage)
    }
  }, [location.pathname, isHerondBrowser])

  return (
    <>
      <BrowserCheckContext.Provider value={{ isHerondBrowser }}>
        {children}
      </BrowserCheckContext.Provider>
    </>
  )
}

export default BrowserCheckProvider
