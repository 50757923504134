import { FC } from 'react'
import styled from 'styled-components'

import SectionIntro from './SectionIntro'
import SectionFeature from './SectionFeature'
import SectionBanner from './SectionBanner'
import SectionPotential from './SectionPotential'
import SectionOverView from './SectionOverView'

const Wrapper = styled.div`
  [scroll-data] {
    height: 100vh;
    width: 100%;
  }
`

const Home: FC = () => {
  return (
    <Wrapper>
      <SectionIntro />
      <SectionFeature />
      <SectionPotential />
      <SectionOverView />
      <SectionBanner />
    </Wrapper>
  )
}

export default Home
