import { UserAPI } from '@/constants/api'
import http from '@/utils/http'
export default class UserService {
  static postRegisterUser = async () => {
    try {
      const res = await http.post<boolean, unknown>(
        UserAPI.registration,
        null,
        {
          withCredentials: true,
        },
      )
      if (res.success) {
        return {
          code: res.code || '',
          message: res.message || '',
          success: true,
        }
      } else {
        return {
          code: res.code || '',
          message: res.message,
          success: false,
        }
      }
    } catch (e) {
      //
    }
    return undefined
  }
}
