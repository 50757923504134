import styled from '@emotion/styled'

import useModel from '@/context/ModelContext'
import images from '@/utils/images'

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &:hover {
    cursor: pointer;
    background-color: #e4e4e4;
    border-radius: 20%;
  }
`

const RequestModalStyled = styled.section`
  position: fixed;
  z-index: 100;
  padding: 15px;
  /* position: absolute; */
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  width: 400px;
  border-radius: 8px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RequestConfirmationPopup = () => {
  const useModal = useModel()
  const hideModal = () => {
    useModal.setModelContent(undefined)
    useModal.hideModal()
  }
  return (
    <RequestModalStyled onClick={(e) => e.preventDefault()}>
      <CloseButton onClick={hideModal}>
        <img
          src={images.closeButtonIcon}
          alt=""
          style={{ width: '20px', height: '20px' }}
        />
      </CloseButton>
    </RequestModalStyled>
  )
}
