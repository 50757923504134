import { Container } from '@mui/material'
import React from 'react'
import styled, { keyframes } from 'styled-components'

import imgCycle from '@/assets/feature-cycle.svg'
import herondIcon from '@/assets/icon-herond.svg'
import dashlineIcon from '@/assets/icon-dashline.svg'
import signupIcon from '@/assets/icon-signup.svg'
import sharingIcon from '@/assets/icon-sharing.svg'
import earningIcon from '@/assets/icon-earning.svg'
import { useTranslation } from 'react-i18next'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .group-cycle {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .cycle {
      position: absolute;
      border-radius: 50%;
      border: 2px solid #e9eef5;
      aspect-ratio: 1 / 1;
      animation: ${rotate} 40s linear infinite;
      svg {
        color: #e9eef5;
      }
    }

    .cycle-1 {
      width: 15.625em;
    }

    .cycle-2 {
      width: 27.5em;
      border: none;
    }

    .cycle-3 {
      width: 36.8em;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .group-cycle-1 {
    .cycle-3 {
      .ball--white {
        position: absolute;
        bottom: 20%;
        right: 6%;
        width: 1.375em;
        aspect-ratio: 1 / 1;
        border: 3px solid #e9eef5;
        background: #ffffff;
        border-radius: 50%;
      }
    }
  }
`

const StyledContainer = styled(Container)`
  max-width: 1242px !important;
  padding-top: 225px;

  @media only screen and (max-width: 992px) {
    padding-top: 80px;
    img {
      width: 364px;
      height: 266px;
    }
  }
`

const StyledTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.5;
  z-index: 2;
  margin-bottom: 112px;
  span {
    color: #ff821c;
  }

  @media (max-width: 992px) {
    margin-bottom: 80px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 1.5em;
  }
`

const ProgressIllustrationStyled = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 29px;
  margin-bottom: 225px;

  @media (max-width: 575px) {
    margin-bottom: 80px;
  }

  @media (min-width: 576px) {
    margin-bottom: 120px;
  }

  @media (min-width: 900px) {
    margin-bottom: 225px;
  }

  .progress-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    max-width: 235px;

    img {
      margin-bottom: 55px;
      background-color: white;
    }

    .earning-icon {
      margin-bottom: 36px;
    }
  }

  .progress-item-explain {
    h2 {
      font-size: 22px;
      line-height: 1.5;
      font-weight: 500;
      margin-bottom: 15px;
    }

    p {
      color: #32373d;
      line-height: 1.5;
    }
  }

  .dashline {
    position: absolute;
    z-index: -1;
    top: 20%;
    right: -50%;
  }

  @media (max-width: 992px) {
    .progress-item {
      img {
        width: 80px;
        height: auto;
        margin-bottom: 36px;
      }
    }

    .progress-item-explain {
      h2 {
        font-size: 14px;
      }

      p {
        font-size: 12px;
      }
    }

    .dashline {
      position: absolute;
      z-index: -1;
      top: 15%;
      right: -40%;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
    .dashline {
      display: none;
    }

    .progress-item {
      img {
        margin-bottom: 20px;
        width: 100px;
      }
    }

    .progress-item-explain {
      max-width: 220px;
      margin-bottom: 60px;

      h2 {
        font-size: 1.375rem;
      }

      p {
        font-size: 16px;
      }
    }
  }
`

const SectionFeature: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledWrapper id="features" anchor-data="features">
      <div className="group-cycle group-cycle-1">
        <div className="cycle cycle-1">
          <div className="ball"></div>
        </div>
        <img src={imgCycle} alt="" className="cycle cycle-2" />
        <div className="cycle cycle-3">
          <div className="ball--white"></div>
          <div className="ball--color"></div>
        </div>
      </div>

      <div className="group-cycle group-cycle-2">
        <div className="cycle cycle-1">
          <div className="ball"></div>
        </div>
        <img src={imgCycle} alt="" className="cycle cycle-2" />
        <div className="cycle cycle-3">
          <div className="ball--white"></div>
          <div className="ball--color"></div>
        </div>
      </div>

      <div className="group-cycle group-cycle-3">
        <div className="cycle cycle-1">
          <div className="ball"></div>
        </div>
        <img src={imgCycle} alt="" className="cycle cycle-2" />
        <div className="cycle cycle-3">
          <div className="ball--white"></div>
          <div className="ball--color"></div>
        </div>
      </div>
      <StyledContainer>
        <StyledTitle>
          {t('home.itEasyTo')}
          <span> {t('home.getStart')}</span>
        </StyledTitle>
        <ProgressIllustrationStyled>
          <div className="progress-item">
            <img src={herondIcon} alt="herond" />
            <div className="progress-item-explain">
              <h2>{t('home.installHerond')}</h2>
              <p>{t('home.installHerondSub')}</p>
            </div>
            <img
              src={dashlineIcon}
              alt="herond"
              className="dashline dashline-1"
            />
          </div>
          <div className="progress-item">
            <img src={signupIcon} alt="signup" />
            <div className="progress-item-explain">
              <h2>{t('home.signUp')}</h2>
              <p>{t('home.signUpSub')}</p>
            </div>
            <img
              src={dashlineIcon}
              alt="herond"
              className="dashline dashline-2"
            />
          </div>
          <div className="progress-item">
            <img src={sharingIcon} alt="sharing" />
            <div className="progress-item-explain">
              <h2>{t('home.sharing')}</h2>
              <p>{t('home.sharingSub')}</p>
            </div>
            <img
              src={dashlineIcon}
              alt="herond"
              className="dashline dashline-3"
            />
          </div>
          <div className="progress-item">
            <img src={earningIcon} alt="earning" className="earning-icon" />
            <div className="progress-item-explain">
              <h2>{t('home.earning')}</h2>
              <p>{t('home.earningSub')}</p>
            </div>
          </div>
        </ProgressIllustrationStyled>
      </StyledContainer>
    </StyledWrapper>
  )
}

export default SectionFeature
