import { FC, PropsWithChildren } from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import styled from 'styled-components'

const InformationStyled = styled.div`
  color: #a1a9b5;
  font-size: 1.25rem;
  position: relative;

  &:hover {
    cursor: pointer;

    .icon-explain {
      visibility: visible;
      opacity: 1;
    }
  }

  .icon-explain {
    z-index: 10;
    position: absolute;
    width: 285px;
    background-color: #1d1f23;
    color: white;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 6px;
    padding: 10px;
    bottom: 40px;
    left: -155px;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #1d1f23;
    }
  }
`

const InformationIcon: FC<PropsWithChildren> = ({ children }) => {
  return (
    <InformationStyled>
      <HiInformationCircle className="information-icon" />
      <div className="icon-explain">{children}</div>
    </InformationStyled>
  )
}

export default InformationIcon
