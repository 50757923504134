import { Grid } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'

import FAQ from '@/components/FAQ'
import { useTranslation } from 'react-i18next'

const WrapperStyled = styled(Grid)`
  width: 100%;
`

const ProgramPolicy = styled.section`
  background-color: white;
  padding: 30px 60px;
  border-radius: 10px;
  margin-bottom: 30px;

  @media (max-width: 899px) {
    padding: 10px 20px;
    margin: 0px;
  }

  & > h1 {
    font-size: 1.625rem;
    color: #ff821c;
    margin-bottom: 20px;
    font-weight: 500;
  }

  & > h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .condition {
    margin-bottom: 40px;
    line-height: 1.4;

    & > h3 {
      font-size: 1.125rem;
      font-weight: 500;
      padding-left: 5px;
    }

    .dot-line {
      position: relative;
      padding-left: 30px;

      &::before {
        content: '.';
        position: absolute;
        top: -4px;
        left: 5px;
        font-size: 50px;
        line-height: 0;
      }
    }
  }
`

const PolicyFAQStyled = styled.div`
  background-color: white;
  padding: 60px 80px;
  border-radius: 10px;

  @media (max-width: 899px) {
    padding: 10px 20px;
    margin-top: 15px;
  }

  & > h1 {
    font-size: 1.625rem;
    font-weight: 500;
    color: #ff821c;
  }
`

const Policy: FC = () => {
  const { t } = useTranslation()
  return (
    <WrapperStyled>
      <ProgramPolicy>
        <h1>{t('policy.affiliateProgramPolicy')}</h1>
        <h2>{t('policy.weValueYouSuccess')}</h2>
        <div className="condition">
          <h3>1. {t('policy.eligibleConditionsForAffiliates')}</h3>
          <div>
            <p>{t('policy.eligibleConditionsForAffiliates1')}</p>
            <p className="dot-line">
              {t('policy.eligibleConditionsForAffiliates2')}
            </p>
            <p className="dot-line">
              {t('policy.eligibleConditionsForAffiliates3')}
            </p>
            <p className="dot-line">
              {' '}
              {t('policy.eligibleConditionsForAffiliates4')}
            </p>
          </div>
        </div>
        <div className="condition">
          <h3>2. {t('policy.elligibleUserCount')}</h3>
          <div>
            <p>{t('policy.elligibleUserCount1')}: </p>
            <p className="dot-line">{t('policy.elligibleUserCount2')}</p>
            <p className="dot-line">{t('policy.elligibleUserCount3')}</p>
            <p className="dot-line"> {t('policy.elligibleUserCount4')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>3. {t('policy.aboutRewards')}</h3>
          <div>
            <p className="dot-line">{t('policy.aboutRewards2')}</p>
            <p className="dot-line">{t('policy.aboutRewards1')}</p>
            <p className="dot-line">{t('policy.aboutRewards3')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>4. {t('policy.referralTracking')}</h3>
          <div>
            <p>{t('policy.referralTracking1')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>5. {t('policy.penalties')}</h3>
          <div>
            <p>{t('policy.penalties1')} </p>
            <p className="dot-line">{t('policy.penalties2')}</p>
            <p className="dot-line">{t('policy.penalties3')}</p>
            <p className="dot-line">{t('policy.penalties4')}</p>
            <p className="dot-line">{t('policy.penalties5')}</p>
            <p className="dot-line">{t('policy.penalties6')}</p>
            <p className="dot-line">{t('policy.penalties7')}</p>
            <p>{t('policy.penalties8')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>6. {t('policy.violationHandling')}</h3>
          <div>
            <p>{t('policy.violationHandling1')}: </p>
            <p className="dot-line">{t('policy.violationHandling2')}</p>
            <p className="dot-line">{t('policy.violationHandling3')}</p>
            <p className="dot-line">{t('policy.violationHandling4')}</p>
            <p className="dot-line">{t('policy.violationHandling5')}</p>
            <p className="dot-line">{t('policy.violationHandling6')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>7. {t('policy.paymentWithdraw')}</h3>
          <div>
            <p>{t('policy.paymentWithdraw1')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>8. {t('policy.termination')}</h3>
          <div>
            <p>{t('policy.termination1')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>9. {t('policy.modification')}</h3>
          <div>
            <p>{t('policy.modification1')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>10. {t('policy.disclaimer')}</h3>
          <div>
            <p className="dot-line">{t('policy.disclaimer1')}</p>
            <p className="dot-line">{t('policy.disclaimer2')}</p>
            <p className="dot-line">{t('policy.disclaimer3')}</p>
            <p className="dot-line">{t('policy.disclaimer4')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>11. {t('policy.forceMajeure')}</h3>
          <div>
            <p className="dot-line">{t('policy.forceMajeure1')}</p>
            <p className="dot-line">{t('policy.forceMajeure2')}</p>
            <p className="dot-line">{t('policy.forceMajeure3')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>12. {t('policy.affiliateLinkNaming')}</h3>
          <div>
            <p>{t('policy.affiliateLinkNaming1')} </p>
            <p>{t('policy.affiliateLinkNaming2')}:</p>
            <p className="dot-line">{t('policy.affiliateLinkNaming3')}</p>
            <p className="dot-line">{t('policy.affiliateLinkNaming4')}</p>
            <p className="dot-line">{t('policy.affiliateLinkNaming5')}</p>
            <p className="dot-line">{t('policy.affiliateLinkNaming6')}</p>
            <p className="dot-line">{t('policy.affiliateLinkNaming7')}</p>
          </div>
        </div>

        <div className="condition">
          <h3>13. {t('policy.dataCollection')}</h3>
          <div>
            <p>{t('policy.dataCollection1')}</p>
          </div>
        </div>
      </ProgramPolicy>

      <PolicyFAQStyled>
        <h1>FAQ</h1>
        <FAQ />
      </PolicyFAQStyled>
    </WrapperStyled>
  )
}

export default Policy
