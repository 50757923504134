import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import config from '@/config'
import { useAuth } from '@/context/AuthProvider'

const NavbarWrapper = styled.section`
  /* position: fixed; */
  width: 100%;
  min-height: calc(100vh - 68px);
  display: flex;
  flex-direction: column;
  padding: 30px 12px 0;
  box-sizing: border-box;
  align-items: center;

  .lineBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0 1.5em 0;

    .line1 {
      flex: auto;
      width: 100%;
      height: 1px;
      background-color: #f9be4f;
    }

    span {
      color: #696868;
      margin: 0 10px;
      font-weight: 500;
      @media (max-width: 1200px) {
        display: none;
      }
    }

    .line2 {
      flex: auto;
      width: 100%;
      height: 1px;
      background-color: #f7c872;

      @media (max-width: 1200px) {
        display: none;
      }
    }
  }
`

const NavbarItem = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 15px;
  gap: 20px;
  margin-bottom: 10px;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 6px;
  color: #7a818c;
  &:hover {
    background-color: #ffa55c;
    color: white;
  }

  &.active {
    background-color: #ff821c;
    color: white;
  }

  @media (max-width: 1200px) {
    width: 51px;
    .title {
      display: none;
    }
  }
`

const NavbarVertical: FC = () => {
  const [activeNav, setActiveNav] = useState<string | undefined>('')
  const location = useLocation()
  const { user } = useAuth()
  const isAdminRole = user?.isAdmin || false
  const hasReferralCode = user ? user.hasReferralCode : false
  const checkingNavListWithReferralCode = [
    'dashboard.header.payment',
    'dashboard.header.link-management',
  ]

  useEffect(() => {
    for (let i = 0; i < config.dashboardNavLink.length; i++) {
      if (!config.dashboardNavLink[i].to) return
      if (config.dashboardNavLink[i].to === location.pathname) {
        setActiveNav(config.dashboardNavLink[i].to)
      }
    }

    for (let i = 0; i < config.adminNavLink.length; i++) {
      if (!config.adminNavLink[i].to) return
      if (config.adminNavLink[i].to === location.pathname) {
        setActiveNav(config.adminNavLink[i].to)
      }
    }
  }, [location.pathname])

  return (
    <NavbarWrapper>
      {config.dashboardNavLink.map((nav) => (
        <NavbarItem
          key={nav.title}
          to={nav.to!}
          className={`${activeNav === nav.to ? 'active' : ''}`}
          style={
            !hasReferralCode &&
            checkingNavListWithReferralCode.includes(nav.title)
              ? { opacity: 0.2, pointerEvents: 'none', cursor: 'not-allowed' }
              : {}
          }
        >
          {nav.icon}
          <div className="title">{t(nav.title)}</div>
        </NavbarItem>
      ))}
      {isAdminRole && (
        <>
          <div className="lineBox">
            <div className="line1" />
            <span>Admin</span>
            <div className="line2" />
          </div>
          {config.adminNavLink.map((nav) => (
            <NavbarItem
              key={nav.title}
              to={nav.to!}
              className={`${activeNav === nav.to ? 'active' : ''}`}
            >
              {nav.icon}
              <div className="title">{t(nav.title)}</div>
            </NavbarItem>
          ))}
        </>
      )}
    </NavbarWrapper>
  )
}

export default NavbarVertical
