import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit'

import { Toast } from '@/constants/types'

export interface ApplicationState {
  readonly isShowDrawer: boolean
  readonly toasts: Toast[]
}

const initialState: ApplicationState = {
  isShowDrawer: false,
  toasts: [],
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateIsShowDrawer(state, action: PayloadAction<{ val: boolean }>) {
      const { val } = action.payload
      state.isShowDrawer = val
    },
    addToast(state, { payload }: PayloadAction<Omit<Toast, 'id'>>) {
      state.toasts = [...state.toasts, { id: nanoid(), ...payload }]
    },
    removeToast(state, { payload }: PayloadAction<{ id: string }>) {
      const index = state.toasts.findIndex((x) => x.id === payload.id)
      if (index > -1) {
        state.toasts.splice(index, 1)
      }
    },
  },
})

export const { updateIsShowDrawer, addToast, removeToast } =
  applicationSlice.actions
export default applicationSlice.reducer
