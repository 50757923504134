import { RequestSupportResponseType, SupportAPI } from '@/constants/api'
import http from '@/utils/http'

export default class SupportService {
  static getLinkRequestData = async () => {
    try {
      const res = await http.get<RequestSupportResponseType>(
        SupportAPI.requestSupport,
        {
          withCredentials: true,
        },
      )

      if (res.success && res.data) {
        return res
      } else {
        return {
          code: res.code || '',
          message: res.message,
          success: false,
        }
      }
    } catch (e) {
      //
    }
    return undefined
  }
}
