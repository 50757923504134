import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import application from './application/reducer'

const store = configureStore({
  reducer: {
    application,
  },
})
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types

export default store
