import { FC, useState } from 'react'
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { replaceStringToEmail } from '@/utils/changeTextToLink'

const FAQdummy = [
  {
    id: '1',
    question: 'faq.question1',
    answer: 'faq.answer1',
  },
  {
    id: '2',
    question: 'faq.question2',
    answer: 'faq.answer2',
  },
  {
    id: '3',
    question: 'faq.question3',
    answer: 'faq.answer3',
  },
  {
    id: '4',
    question: 'faq.question4',
    answer: 'faq.answer4',
  },
]

const FAQStyled = styled.div`
  width: 100%;

  .FAQ-item {
    padding: 20px 0;
    border-top: 1px solid #dce0e3;
    transition: all 1s;

    &.active {
      .answer {
        height: 50px;
        opacity: 1;
      }
    }

    &:first-of-type {
      border: none;
    }

    a {
      color: #298cfd;
      transition: color 300ms ease;
      :hover {
        color: #0066f4;
      }
    }
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    gap: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
      svg {
        color: #ff821c;
      }
    }

    svg {
      font-size: 30px;
      cursor: pointer;
      transition: all 500ms;
    }
  }

  .answer {
    font-size: 1.125rem;
    color: #40474f;
    line-height: 1.6;
    transition: height 0.3s linear, opacity 0.2s linear;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  @media (max-width: 992px) {
    .question {
      font-size: 1.125rem;
    }
    .answer {
      font-size: 1rem;
    }

    .FAQ-item {
      &.active {
        .answer {
          height: 70px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .FAQ-item {
      &.active {
        .answer {
          height: 100px;
        }
      }
    }
  }
`

const FAQ: FC = () => {
  const { t } = useTranslation()
  const [FAQShow, setFAQShow] = useState<string>('1')

  return (
    <FAQStyled>
      <div className="FAQ-group">
        {FAQdummy.map((item) => (
          <div
            key={item.id}
            className={`${FAQShow === item.id ? 'active' : ''} FAQ-item`}
          >
            <div
              className="question"
              onClick={() => setFAQShow(item.id === FAQShow ? '' : item.id)}
            >
              <p>{t(item.question)}</p>
              {FAQShow === item.id ? <VscChevronDown /> : <VscChevronUp />}
            </div>
            <div className={`answer-${item.id} answer`}>
              {replaceStringToEmail(
                t(item.answer),
                'affilate dashboard',
                'https://affiliate.herond.org/dashboard/overview',
              )}
            </div>
          </div>
        ))}
      </div>
    </FAQStyled>
  )
}

export default FAQ
