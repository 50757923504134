export const PRIMARY_COLOR = '#F7941D'

export const getCookieValue = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

export const copyToClipboard = (text: string) => {
  const tmp = document.createElement('input')
  tmp.value = text
  document.body.appendChild(tmp)
  tmp.select()
  document.execCommand('copy')
  tmp.remove()
}

export const isValidateEmail = (input: string) => {
  // eslint-disable-next-line no-useless-escape
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  if (input && input.match(isValidEmail)) {
    return true
  } else {
    return false
  }
}

const cultureMap: { [key: string]: string } = {
  'en-US': 'en',
  vi: 'vi',
}

export const parseCulture = (str?: string) => {
  const regex = /c=(vi|en-US)/
  const match = str && str.match(regex)
  if (match && match[1] && cultureMap[match[1]]) {
    return cultureMap[match[1]]
  } else {
    return undefined
  }
}

export const convertAddressArrToString = (
  arrAddress: string[],
  numStart = 4,
  numEnd = 4,
) => {
  if (arrAddress && arrAddress.length === 1 && arrAddress[0]) {
    return (
      arrAddress[0].substring(0, numStart) +
      '...' +
      arrAddress[0].substring(
        arrAddress[0].length - numEnd,
        arrAddress[0].length,
      )
    )
  } else if (arrAddress && arrAddress.length > 1) {
    let stringTemp = ''
    arrAddress.map((item, index) => {
      if (index !== arrAddress.length - 1) {
        stringTemp += convertAddressArrToString([item]) + '\n'
      } else {
        stringTemp += convertAddressArrToString([item])
      }
    })

    return stringTemp
  }
}
