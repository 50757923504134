import styled from 'styled-components'
import { FC } from 'react'
import { BsXLg } from 'react-icons/bs'

import hreondLogo from '@/assets/herond-logo-icon.svg'
import useModel from '@/context/ModelContext'

import BrowserDownloadButton from './BrowserDownloadButton'

const ModelDownloadStyled = styled.section`
  position: fixed;
  z-index: 9;
  inset: 0;
  background: #00000040;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    display: none;
  }

  &.show {
    display: flex;
  }
`

const ModelDownloadContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 10px;
  padding: 40px 0;
  margin: 0 30px;
  .cancel {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }

  .herond {
    height: 100px;
    width: 100px;
    margin-bottom: 20px;
  }

  h2 {
    width: 390px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 500;
    line-height: 1.5;
  }
`

const ModelDownLoad: FC = () => {
  const openModal = useModel()

  const onHandleClosePopup = () => {
    openModal.setModelContent()
    openModal.hideModal()
  }

  return (
    <ModelDownloadStyled className={'show'}>
      <ModelDownloadContent>
        <BsXLg className="cancel" onClick={onHandleClosePopup} />
        <img src={hreondLogo} alt="herond" className="herond" />
        <h2>
          Please install Herond Browser and create an account to join the Herond
          Affiliate Program
        </h2>
        <BrowserDownloadButton />
      </ModelDownloadContent>
    </ModelDownloadStyled>
  )
}

export default ModelDownLoad
