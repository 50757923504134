import {
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'

import React, { FC, useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import styled from 'styled-components'

import LinkManagementService from '@/services/linkManagementService'
import { useAddToast } from '@/state/application/hooks'
import { LinkAffiliateArrType } from '@/constants/api'
import { CreateNewLinkPopup } from './components/CreateNewLinkPopup'
import useModel from '@/context/ModelContext'
import images from '@/utils/images'
import { copyToClipboard } from '@/utils'
import SkeletonContainer from '@/components/SkeletonContainer'
import { DeleteLinkPopup } from './components/DeleteLinkPopup'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DCE0E3',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const LinkManagementWrapperStyled = styled.section`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: 900px) {
    padding: 10px;
    height: 100%;
  }

  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & > h2 {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  .row {
    &:hover {
      cursor: pointer;
      background-color: #eaecee;
    }
  }

  .create-link {
    display: flex;
    align-items: center;
    gap: 30px;

    & > div {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 10px;
      color: #298cfd;
      border: 1px solid #298cfd;
      border-radius: 6px;
      padding: 8px 16px;
      cursor: pointer;
      transition: all 10s ease;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        background: linear-gradient(0deg, #f0f7ff, #f0f7ff);
        opacity: 0;
        transition: all 500ms ease;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

const HistoryTableStyled = styled.div`
  margin-bottom: 40px;
  .row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dce0e3;
    color: #32373d;

    &.header {
      background-color: #e8eaed;
      font-weight: 500;
    }

    .cell {
      padding: 9px;
      min-width: calc((100% - 4% - 25%) / 5);
    }

    .cell-no {
      min-width: 2%;
    }

    .cell-link {
      min-width: 25%;
    }

    .tableContainer {
      width: 100%;
      height: 65vh;

      @media (max-width: 576px) {
        height: 100%;
      }
    }
  }
`

const ActionBox = styled(Grid)`
  width: 100%;
  display: flex;
  gap: 5px;
`

const IconBox = styled(Grid)`
  flex: 1;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
  .actionIcon {
  }
`

const LoadingBoxStyled = styled.div`
  width: 100%;
  min-width: 700;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoDataBoxStyled = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-top: 10vh;
`

const LinkManagement: FC = () => {
  const addToast = useAddToast()
  const { t } = useTranslation()
  const useModal = useModel()
  const [linkUrlData, setLinkUrlData] = useState<LinkAffiliateArrType[]>()
  const [isLoadingTable, setIsLoadingTable] = useState(true)

  const getLinkUrlApi = async () => {
    setIsLoadingTable(true)
    const linkRes = await LinkManagementService.getLinkAffiliateData()
    if (linkRes && linkRes.length >= 0) {
      setLinkUrlData(linkRes)
    } else {
      addToast({
        msg: 'Can not get link management data!',
      })
    }

    setTimeout(() => {
      setIsLoadingTable(false)
    }, 500)
  }

  const callBackAfterCreateDone = () => {
    useModal.setModelContent(undefined)
    useModal.hideModal()
    getLinkUrlApi()
  }

  const callBackAfterDeleteDone = () => {
    useModal.setModelContent(undefined)
    useModal.hideModal()
    getLinkUrlApi()
  }

  const onHandleOpenCreateLinkPopup = () => {
    if (linkUrlData && linkUrlData?.length <= 5) {
      useModal.setModelContent(
        <CreateNewLinkPopup
          callBackAfterCreateDone={callBackAfterCreateDone}
        />,
      )
      useModal.showModal()
    }
  }

  useEffect(() => {
    getLinkUrlApi()
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const LoadingWaitingBox = () => {
    return (
      <LoadingBoxStyled>
        <Skeleton
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sx={{
            maxWidth: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            height: '60vh',
            transform: 'scale(1)',
          }}
        >
          <CircularProgress
            style={{ visibility: 'inherit', opacity: 0.5 }}
            size={30}
            color="inherit"
          />
        </Skeleton>
      </LoadingBoxStyled>
    )
  }

  const onHandleCopyLink = (referralCode: string) => {
    copyToClipboard(`https://herond.org?ref=${referralCode}`)
    addToast({
      msg: 'Copied',
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const NoDataBox = () => {
    return (
      <NoDataBoxStyled>
        <span>{t('noData')}</span>
      </NoDataBoxStyled>
    )
  }

  const onHandleDeleteLink = async (referralCode: string) => {
    useModal.setModelContent(undefined)
    useModal.hideModal()

    setIsLoadingTable(true)
    const linkRes = await LinkManagementService.postDeleteLinkAffiliateData(
      referralCode,
    )

    if (linkRes && linkRes.message) {
      addToast({
        msg: linkRes.message,
      })
    }

    if (linkRes && linkRes.success && callBackAfterDeleteDone !== undefined) {
      getLinkUrlApi()
    } else {
      setIsLoadingTable(false)
    }
  }

  const onHandleOpenDeletePopup = (referralCode: string) => {
    useModal.setModelContent(
      <DeleteLinkPopup
        onHandleDeleteCallback={() => onHandleDeleteLink(referralCode)}
        callBackAfterDeleteDone={callBackAfterDeleteDone}
        referralCode={referralCode}
      />,
    )
    useModal.showModal()
  }

  return (
    <LinkManagementWrapperStyled>
      <div className="history-header">
        <h2>{t('linkList.linkList')}</h2>
        <div className="create-link">
          <p>
            {linkUrlData?.length || 0} {t('items')}
          </p>
          {linkUrlData && linkUrlData?.length <= 5 && (
            <div onClick={onHandleOpenCreateLinkPopup}>
              <AiOutlinePlus /> {t('linkList.createLink')}
            </div>
          )}
        </div>
      </div>
      <SkeletonContainer isLoading={isLoadingTable}>
        <HistoryTableStyled>
          <TableContainer
            // style={{ height: '60vh', width: '100%' }}
            className="tableContainer"
            component={Paper}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 700 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t('stt')}</StyledTableCell>
                  <StyledTableCell align="right">
                    {t('payment.creationTime')}
                  </StyledTableCell>
                  <StyledTableCell align="right">Code</StyledTableCell>
                  <StyledTableCell align="right">
                    {t('linkList.clicks')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t('linkList.Downloads')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t('overview.validUser')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t('overview.earnMoney')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t('linkList.action')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {linkUrlData && linkUrlData.length > 0 ? (
                <TableBody>
                  {linkUrlData.map((row, rowIndex) => (
                    <StyledTableRow key={rowIndex}>
                      <StyledTableCell component="th" scope="row">
                        {rowIndex + 1}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.createdDateTime
                          ? moment(row.createdDateTime).format(
                              'DD-MM-YYYY HH:MM:ss',
                            )
                          : ''}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.referralCode}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.clickCount}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.downloadCount}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.validUser}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.earnedMoney}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <ActionBox>
                          <IconBox
                            onClick={() => onHandleCopyLink(row.referralCode)}
                          >
                            <img
                              src={images.copyIcon}
                              alt="herond"
                              className="actionIcon"
                            />
                          </IconBox>
                          <IconBox
                            onClick={() =>
                              onHandleOpenDeletePopup(row.referralCode)
                            }
                          >
                            <img
                              src={images.deleteIcon}
                              alt="herond"
                              className="actionIcon"
                            />
                          </IconBox>
                        </ActionBox>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <NoDataBox key={'noData'} />
              )}
            </Table>
          </TableContainer>
        </HistoryTableStyled>
      </SkeletonContainer>
    </LinkManagementWrapperStyled>
  )
}

export default LinkManagement
