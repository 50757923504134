import i18n, { use, changeLanguage as changeLanguageI18N } from 'i18next'
import { initReactI18next } from 'react-i18next'

import enLang from './en.json'
import viLang from './vi.json'
import enImg from './en.png'
import viImg from './vi.png'

export const languageDetail = {
  en: {
    nativeName: 'English',
    inEng: 'English',
    flag: enImg,
  },
  vi: {
    nativeName: 'Tiếng Việt',
    inEng: 'Vietnamese',
    flag: viImg,
  },
}

export type LangCode = keyof typeof languageDetail

const setLang = (value: LangCode) => {
  localStorage.setItem('REACT_LANG', value)
}

enum Language {
  vi = 'vi',
  en = 'en',
}

const getLang = (): LangCode => {
  const lang = localStorage.getItem('REACT_LANG') as Language
  return lang && lang in languageDetail ? lang : 'en'
}

use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enLang,
      },
      vi: {
        translation: viLang,
      },
    },
    lng: getLang(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const changeLanguage = (value: LangCode) => {
  changeLanguageI18N(value)
  setLang(value)
}

export const getLangInfo = (value: LangCode) => {
  return languageDetail[value]
}

export default i18n
