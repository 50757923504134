import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { MdHelp } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import imgHerondLogo from '@/assets/herond-logo-dark.svg'
import FooterMenu from './FooterMenu'
import GroupSocial from './GroupSocial'

const HERONDROUTER = 'https://herond.org/'

const menu: {
  title: string
  items: {
    label: string
    link?: string
    type: 'internal' | 'external'
  }[]
}[] = [
  {
    title: 'footer.browser',
    items: [
      {
        label: 'footer.desktop',
        link: `${HERONDROUTER}browser`,
        type: 'external',
      },
      {
        label: 'footer.android',
        link: `${HERONDROUTER}browser/android`,
        type: 'external',
      },
      {
        label: 'footer.iOS',
        link: `${HERONDROUTER}browser/ios`,
        type: 'external',
      },
      { label: 'footer.dev', link: '', type: 'external' },
      { label: 'footer.nightly', link: '', type: 'external' },
    ],
  },
  {
    title: 'footer.privacy_policy',
    items: [
      {
        label: 'footer.herond_browser',
        link: `${HERONDROUTER}privacy-policy/herond-browser`,
        type: 'external',
      },
      {
        label: 'footer.website',
        link: `${HERONDROUTER}privacy-policy/website-policy`,
        type: 'external',
      },
      {
        label: 'footer.terms_of_use',
        link: `${HERONDROUTER}privacy-policy/terms-of-use`,
        type: 'external',
      },
    ],
  },
  {
    title: 'footer.company',
    items: [
      {
        label: 'footer.about',
        link: `${HERONDROUTER}about-us`,
        type: 'external',
      },
      { label: 'footer.career', link: '', type: 'external' },
    ],
  },
  {
    title: 'footer.affiliate_program',
    items: [
      {
        label: 'footer.policy',
        link: 'https://affiliate.herond.org/dashboard/policy',
        type: 'internal',
      },
      { label: 'footer.registration', link: '/', type: 'internal' },
      {
        label: 'footer.dashboard',
        link: 'dashboard/overview',
        type: 'internal',
      },
      {
        label: 'footer.withdraw',
        link: 'dashboard/payment',
        type: 'internal',
      },
    ],
  },
  {
    title: 'footer.products',
    items: [
      {
        label: 'footer.herond_browser',
        link: `${HERONDROUTER}herond-ecosystem/herond-browser`,
        type: 'external',
      },
      {
        label: 'footer.herond_shield',
        link: `${HERONDROUTER}herond-ecosystem/herond-shield`,
        type: 'external',
      },
      {
        label: 'footer.herond_wallet',
        link: `${HERONDROUTER}herond-ecosystem/herond-wallet`,
        type: 'external',
      },
      {
        label: 'footer.herond_ID',
        link: `${HERONDROUTER}herond-ecosystem/herond-id`,
        type: 'external',
      },
      {
        label: 'footer.herond_chain',
        link: `${HERONDROUTER}herond-ecosystem/herond-chain`,
        type: 'external',
      },
      {
        label: 'footer.herond_mail',
        link: `${HERONDROUTER}herond-ecosystem/herond-mail`,
        type: 'external',
      },
      {
        label: 'footer.herond_go',
        link: `${HERONDROUTER}herond-ecosystem/herond-go`,
        type: 'external',
      },
      {
        label: 'footer.herond_X',
        link: `${HERONDROUTER}herond-ecosystem/herond-X`,
        type: 'external',
      },
    ],
  },
  {
    title: 'footer.resources',
    items: [
      { label: 'footer.faq', link: `${HERONDROUTER}FAQ`, type: 'external' },
      {
        label: 'footer.help_center',
        link: 'https://help.herond.org/',
        type: 'external',
      },
      {
        label: 'footer.community',
        link: `${HERONDROUTER}get-help/community`,
        type: 'external',
      },
      {
        label: 'footer.transparency_report',
        link: `${HERONDROUTER}why-herond/web3-guide`,
        type: 'external',
      },
      {
        label: 'footer.web_3_intro',
        link: `${HERONDROUTER}why-herond/web3-guide`,
        type: 'external',
      },
    ],
  },
]

const StyledContainer = styled.div`
  padding: 60px 195px 70px;

  @media screen and (max-width: 1536px) {
    padding: 60px 120px 70px;
  }

  @media screen and (max-width: 1200px) {
    padding: 60px;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 20px;
  }
`

const StyledBlockRight = styled(Grid)`
  .contact {
    padding: 20px 0;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .contact-title {
    font-weight: bold;
  }

  .contact-email {
    transition: all 500ms ease;
    &:hover {
      color: #ff821c;
    }
  }
  @media screen and (max-width: 992px) {
    border-top: 1px solid #dce0e3;
    padding: 30px 25px 0px 25px;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 650px) {
    display: block;
    text-align: center;
    .group-social {
      justify-content: center;
    }
  }

  @media screen and (max-width: 576px) {
    border: none;
  }
`

const StyledLogo = styled.img`
  height: auto;
  margin-bottom: 25px;
`

const StyledCopyright = styled.div`
  padding: 40px 195px;
  font-size: 0.875em;
  line-height: 1.5em;
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7a818c;

  .copy-right--right {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
  }

  .copy-right--help {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    line-height: 1.5;
    svg {
      font-size: 1em;
    }
  }

  .change-language {
    position: relative;
  }

  .copy-right--translate {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 60px;
    color: #202226;
    border: 1px solid #a1a9b5;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  .list-language {
    cursor: pointer;
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #202226;
    border: 1px solid #a1a9b5;
    border-radius: 5%;
    transition: all 0.25s linear;
    &:hover {
      background-color: #a1a9b5;
    }
  }

  @media screen and (max-width: 1536px) {
    padding: 40px 120px;
  }

  @media screen and (max-width: 1200px) {
    padding: 40px 60px;
  }

  @media screen and (max-width: 992px) {
    margin: 0;
    padding-bottom: 50px;
    padding: 40px 25px;
  }

  @media screen and (max-width: 700px) {
    font-size: 0.7em;
  }

  @media only screen and (max-width: 576px) {
    margin: 0;
    flex-direction: column-reverse;
    border: none;
    padding: 0;
    align-items: flex-start;
    font-size: 0.8rem;
    .copy-right--right {
      padding: 0 25px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 50px;
      width: 100%;
    }

    .copy-right--translate {
      justify-content: space-between;
    }

    form {
      width: 100%;
    }

    .copy-right--left {
      width: 100%;
      padding: 10px 25px;
      border-top: 1px solid #dce0e3;
      text-align: center;
    }
  }
`

const Footer: React.FC = () => {
  const [menuHidden, setMenuHidden] = useState<string[]>([])
  const { t } = useTranslation()

  const handleMenu = (menuItem: string) => {
    const hidden = [...menuHidden]
    const index = hidden.indexOf(menuItem)
    if (index > -1) {
      hidden.splice(index, 1)
    } else {
      hidden.push(menuItem)
    }
    setMenuHidden(hidden)
  }

  return (
    <>
      <StyledContainer>
        <Grid container>
          <Grid item xs={12} sm={3} md={2.4}>
            <FooterMenu
              {...menu[0]}
              menuHidden={menuHidden}
              handleMenu={handleMenu}
            />
            <FooterMenu
              {...menu[1]}
              menuHidden={menuHidden}
              handleMenu={handleMenu}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.4}>
            <FooterMenu
              {...menu[2]}
              menuHidden={menuHidden}
              handleMenu={handleMenu}
            />
            <FooterMenu
              {...menu[3]}
              menuHidden={menuHidden}
              handleMenu={handleMenu}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.4}>
            <FooterMenu
              {...menu[4]}
              menuHidden={menuHidden}
              handleMenu={handleMenu}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.4}>
            <FooterMenu
              {...menu[5]}
              menuHidden={menuHidden}
              handleMenu={handleMenu}
            />
          </Grid>
          <StyledBlockRight item xs={12} sm={12} md={2.4}>
            <div className="logo">
              <StyledLogo src={imgHerondLogo} alt="logo" width={180} />
              <GroupSocial />
            </div>
            <div className="contact">
              <div className="contact-title">{t('footer.requests')}</div>
              <a href="mailto:contact@herond.org" className="contact-email">
                contact@herond.org
              </a>
            </div>
            <div className="contact">
              <div className="contact-title">{t('footer.business')}</div>
              <a href="mailto:business@herond.org" className="contact-email">
                business@herond.org
              </a>
            </div>
          </StyledBlockRight>
        </Grid>
      </StyledContainer>
      <StyledCopyright>
        <div className="copy-right--left">
          Copyright © 2023 by Herond Labs. All rights reserved
        </div>
        <div className="copy-right--right">
          <div className="copy-right--help">
            <MdHelp />
            {t('footer.help')}
          </div>
        </div>
      </StyledCopyright>
    </>
  )
}

export default Footer
