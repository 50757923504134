import { Grid } from '@mui/material'
import styled, { keyframes } from 'styled-components'

import BrowserDownloadButton from '@/components/BrowserDownloadButton'
import screenHerond from '@/assets/screen-herond.png'
import balanceImage from '@/assets/balance-img.png'
import shareBubble from '@/assets/share-bubble.png'
import EarningButton from '@/components/EarningButton'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const animationPopup = keyframes`
  0% {
    opacity: 0;
    scale: 0;
  }
  20% {
      opacity: 1;
      scale: 1;
  }
  50% {
    opacity: 1;
    scale: 1;
  }
  60%{
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 0;
    scale: 0;
  }
`

const IntroSectionStyled = styled.div`
  position: relative;
  padding-top: 200px;
  color: white;

  .group-button {
    display: flex;
    gap: 20px;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      padding: 0.8rem 0rem;
      border: 1px solid white;
      border-radius: 40px;
      min-width: 200px;
    }
  }

  .line {
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 102.04%
    );
    border-radius: 0 48.5px 50px 0;
  }

  .line-1 {
    width: 500px;
    height: 35px;
    top: 15%;
    right: -5%;
  }

  .line-2 {
    width: 285px;
    height: 35px;
    top: 25%;
    left: 39%;
  }

  .line-3 {
    width: 439px;
    height: 67px;
    top: 85%;
    left: 5%;
  }

  .line-4 {
    width: 455px;
    height: 52px;
    bottom: -20%;
    left: 45%;
    z-index: 10;
  }

  @media (max-width: 1200px) {
    padding-top: 150px;
  }

  @media (max-width: 992px) {
    .line {
      display: none;
    }
  }

  @media (max-width: 576px) {
    padding-top: 100px;

    .group-button {
      flex-direction: column;
      align-items: center;
    }
  }
`

const IntroContentStyled = styled(Grid)`
  position: relative;
  z-index: 2;
  padding: 0 180px;
  h1 {
    font-size: 3.75rem;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 0;

    span {
      color: #ff821c;
    }
  }

  & > p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 50px;
  }

  @media (max-width: 1536px) {
    padding: 0 100px;
    h1 {
      font-size: 3rem;
    }
  }

  @media (max-width: 1200px) {
    padding-left: 50px;
    h1 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 992px) {
    padding: 0 50px;
  }

  @media (max-width: 576px) {
    padding: 0 30px;

    h1 {
      font-size: 1.6rem;
      text-align: center;
    }
  }
`

const IntroScreenStyled = styled(Grid)`
  position: relative;
  z-index: 2;
  display: flex;
  align-content: flex-start;
  justify-content: start;
  .screen {
    width: 100%;
    height: fit-content;
    object-fit: contain;
  }

  .share-bubble {
    position: absolute;
    z-index: 2;
    top: -12%;
    left: -26%;
    width: 256px;
    height: auto;
    aspect-ratio: 4 / 1;
    animation: ${animationPopup} 5s infinite;
  }

  @media (max-width: 1200px) {
    .share-bubble {
      top: -9%;
      width: 180px;
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`

const IntroPopup = styled.div`
  position: absolute;
  z-index: 2;
  top: 30%;
  left: -15%;
  img {
    width: 270px;
    height: auto;
    aspect-ratio: 1;
    animation: ${animationPopup} 5s infinite;
  }

  @media (max-width: 1200px) {
    img {
      width: 30%;
    }
  }
`

const BlankSectionStyled = styled.div`
  height: 1450px;
  position: relative;
  background-color: white;
  z-index: 2;
  clip-path: circle(2000px at 50% -40%);
  background: radial-gradient(
    52.26% 52.26% at 50% 58.27%,
    #2d343e 12.32%,
    #141c26 100%
  );
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #10151d 0.23%,
      rgba(15, 23, 36, 0) 100%
    );
    opacity: 0.6;
  }

  @media (max-width: 1536px) {
    height: 1200px;
    clip-path: circle(1500px at 50% -25%);
  }

  @media (max-width: 1200px) {
    height: 1100px;
    clip-path: circle(1500px at 50% -40%);
  }

  @media (max-width: 992px) {
    height: 800px;
    clip-path: circle(1000px at 50% -30%);
  }

  @media (max-width: 576px) {
    height: 800px;
    clip-path: circle(500px at 50% 30%);
  }
`

const SectionIntro: FC = () => {
  const { t } = useTranslation()
  return (
    <BlankSectionStyled id="color-section">
      <IntroSectionStyled>
        <Grid container>
          <IntroContentStyled item xs={12} md={7}>
            <h1>
              {t('home.joinOur')}{' '}
              <span>{t('home.browserAffiliateProgram')} </span>
              {t('home.andEarnLucrative')}
            </h1>
            <p>{t('home.subDescription')}</p>
            <div className="group-button">
              <EarningButton />
              <BrowserDownloadButton isSection />
            </div>
          </IntroContentStyled>
          <IntroScreenStyled item xs={0} md={5}>
            <img src={screenHerond} alt="screen" className="screen" />
            <img src={shareBubble} alt="share" className="share-bubble" />
            <IntroPopup>
              <img src={balanceImage} alt="balance" />
            </IntroPopup>
          </IntroScreenStyled>
        </Grid>
        <div className="line line-1"></div>
        <div className="line line-2"></div>
        <div className="line line-3"></div>
        <div className="line line-4"></div>
      </IntroSectionStyled>
    </BlankSectionStyled>
  )
}

export default SectionIntro
