import copyIcon from '@/assets/copyIcon.svg'
import deleteIcon from '@/assets/deleteIcon.svg'
import signupIcon from '@/assets/icon-signup.svg'
import closeButtonIcon from '@/assets/closeButtonIcon.svg'
import loadingSVGIcon from '@/assets/loadingSVGIcon.gif'
import defaultAvatar from '@/assets/defaultAvatar.jpeg'
import peopleIcon from '@/assets/peopleIcon.svg'

const images = {
  signupIcon: signupIcon,
  closeButtonIcon: closeButtonIcon,
  copyIcon: copyIcon,
  deleteIcon: deleteIcon,
  loadingSVGIcon: loadingSVGIcon,
  defaultAvatar: defaultAvatar,
  peopleIcon: peopleIcon,
}

export default images
