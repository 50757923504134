/* eslint-disable @typescript-eslint/no-explicit-any */
import { Outlet, useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'
import styled from 'styled-components'

import { DefaultRoute } from '@/constants/types'
import { ModelProvider } from '@/context/ModelContext'

import Header from '../Header'
import NavbarVertical from '../NavbarVertical'
import BalanceBox from '../BalanceBox'
import { useAuth } from '@/context/AuthProvider'
import Media from 'react-media'

const ContainerStyle = styled.div`
  display: flex;
  margin-top: 72px;
  background-color: #eef1f5;

  @media (max-width: 899px) {
    overflow: unset;
    .navbarVertical {
      flex-basis: 70px;
      width: 70px;
    }

    .outletMobile {
      align-self: center;
      margin: auto;
      flex-basis: auto;
      width: calc(100vw - 30px);
      padding-bottom: 5vh;
      min-height: calc(100vh - 80px);
      background-color: #eef1f5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding-top: 15px;
    }

    .balanceBox {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-basis: auto;
      margin-bottom: 15px;
      width: 100%;
    }
  }

  @media (min-width: 900px) {
    overflow: auto;
    .navbarVertical {
      z-index: 3;
      background-color: white;
      position: fixed;
      flex-basis: 70px;
      width: 70px;
      top: 65px;
      bottom: 0px;
      overflow-y: auto;
    }

    .outlet {
      flex-basis: auto;
      width: 100%;
      margin-left: 70px;
      padding: 15px;
      min-height: calc(100vh - 80px);
      background-color: #eef1f5;
      display: flex;
      overflow: auto;
    }

    .balanceBox {
      flex-basis: auto;
      padding-top: 15px;
      padding-right: 15px;
    }
  }

  @media (min-width: 1200px) {
    .navbarVertical {
      z-index: 3;
      flex-basis: 240px;
      width: 240px;
      background-color: white;
      position: fixed;
      top: 65px;
      bottom: 0px;
      overflow-y: auto;
    }

    .outlet {
      flex-basis: auto;
      margin-left: 240px;
      width: 100%;
      padding: 30px;
      min-height: calc(100vh - 80px);
      background-color: #eef1f5;
      display: flex;
      overflow: auto;
    }

    .balanceBox {
      flex-basis: 300px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 300px;
      padding: 30px 30px 30px 0;
    }
  }
`

const LayoutDefault = () => {
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const isShowBalanceBox =
    location.pathname === DefaultRoute.Dashboard + '/overview' ||
    location.pathname === DefaultRoute.Dashboard + '/payment'
  const hasReferralCode = user ? user.hasReferralCode : false
  useEffect(() => {
    if (user && user.userId && location.pathname === DefaultRoute.Dashboard) {
      navigate(DefaultRoute.Overview)
    }
  }, [user, location, navigate])

  const renderMobile = () => {
    return (
      <ContainerStyle>
        {/* <div className="navbarVertical">
          <NavbarVertical />
        </div> */}
        <div className="outletMobile">
          {isShowBalanceBox && hasReferralCode && (
            <div className="balanceBox">
              <BalanceBox />
            </div>
          )}
          <Outlet />
        </div>
      </ContainerStyle>
    )
  }

  const renderDesktop = () => {
    return (
      <ContainerStyle>
        <div className="navbarVertical">
          <NavbarVertical />
        </div>
        <div className="outlet">
          <Outlet />
        </div>
        {isShowBalanceBox && hasReferralCode && (
          <div className="balanceBox">
            <BalanceBox />
          </div>
        )}
      </ContainerStyle>
    )
  }

  return (
    <ModelProvider>
      <Header themeDefault="light" />
      <Media
        queries={{
          small: '(max-width: 899px)',
          large: '(min-width: 900px)',
        }}
        defaultMatches={{ small: true, large: false }}
      >
        {(matches: { small: any; large: any }) => (
          <>
            {matches.small && renderMobile()}
            {matches.large && renderDesktop()}
          </>
        )}
      </Media>
    </ModelProvider>
  )
}

export default LayoutDefault
