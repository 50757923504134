import { FC } from 'react'
import styled, { keyframes } from 'styled-components'

import moneyIcon from '@/assets/icon-money.svg'
import walletIcon from '@/assets/icon-wallet.svg'
import groupIcon from '@/assets/icon-group.svg'
import imgCycle from '@/assets/feature-cycle.svg'
import FAQ from '@/components/FAQ'
// import LandingPageServices from '@/services/landingpageApi'
// import { addToast } from '@/state/application/reducer'
import formatNumberWithCommas from '@/utils/formatNumberWithComas'
// import { OverViewLandingPageType } from '@/constants/api'
import { useTranslation } from 'react-i18next'
import { useFirebase } from '@/context/FirebaseProvider'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const WrapperStyled = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 100px;

  .group-cycle {
    position: absolute;
    top: 18%;
    right: 10%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    .cycle {
      position: absolute;
      border-radius: 50%;
      border: 2px solid #e9eef5;
      aspect-ratio: 1 / 1;
      animation: ${rotate} 40s linear infinite;
      svg {
        color: #e9eef5;
      }
    }

    .cycle-1 {
      width: 32rem;
      .ball--white {
        position: absolute;
        top: 20%;
        left: 6%;
        width: 1.375rem;
        aspect-ratio: 1 / 1;
        border: 3px solid #e9eef5;
        border-radius: 50%;
        background: white;
      }

      .ball--gray {
        position: absolute;
        top: 80%;
        right: 9.5%;
        height: 10px;
        aspect-ratio: 1 / 1;
        border: 3px solid #e9eef5;
        border-radius: 50%;
        background: #e9eef5;
      }
    }

    .cycle-2 {
      width: 48.125rem;
      border: none;
    }
  }
  @media (max-width: 1536px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 992px) {
    padding: 0 30px;
    .group-cycle {
      display: none;
    }
  }
`

const OverViewStyled = styled.div`
  max-width: 1242px;
  width: 100%;
  margin-bottom: 200px;
  box-sizing: border-box;

  h1 {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 150px;
    margin-bottom: 78px;
    text-align: center;

    span {
      color: #ff821c;
    }
  }

  .overview {
    display: flex;
    gap: 30px;
  }

  @media (max-width: 992px) {
    margin-bottom: 100px;

    h1 {
      margin-top: 100px;
      margin-bottom: 50px;
      font-size: 1.5rem;
    }

    .overview {
      flex-direction: column;
    }
  }
`

const OverViewCardStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #ff8b20;
  border-radius: 14px;
  padding: 45px;
  background-color: white;
  box-sizing: border-box;
  & > img {
    margin-bottom: 20px;
  }

  & > div {
    font-size: 3.75rem;
    font-weight: 500;
    margin-bottom: 10px;
    span {
      font-size: 1.625rem;
      margin-left: 5px;
    }
  }

  & > p {
    font-size: 1.25rem;
  }

  @media (max-width: 1536px) {
    & > div {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 10px;
      span {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 30px;
    & > div {
      font-size: 2.5rem;
      font-weight: 500;
      margin-bottom: 10px;
      span {
        font-size: 1rem;
      }
    }

    & > p {
      font-size: 1rem;
    }
  }

  @media (max-width: 992px) {
    padding: 20px;
  }
`

const OverViewFAQ = styled.div`
  max-width: 1242px;
  width: 100%;
  padding: 0 100px;
  margin-bottom: 240px;
  h1 {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 90px;
    text-align: center;

    span {
      color: #ff821c;
    }
  }

  @media (max-width: 992px) {
    padding: 0 50px;
    margin-bottom: 120px;
    h1 {
      font-size: 1.5rem;
      margin-bottom: 50px;
    }
  }
`

const SectionOverView: FC = () => {
  const { t } = useTranslation()
  const { downloadCount } = useFirebase()
  // const [overview, setOverView] = useState<OverViewLandingPageType>()

  // const getOverViewData = async () => {
  //   const data = await LandingPageServices.getOverview()
  //   if (data) {
  //     setOverView(data)
  //   } else {
  //     addToast({
  //       msg: 'Network Err',
  //     })
  //   }
  // }

  // useEffect(() => {
  //   getOverViewData()
  // }, [])

  return (
    <WrapperStyled>
      <div className="group-cycle group-cycle-1">
        <div className="cycle cycle-1">
          <div className="ball--white"></div>
          <div className="ball--gray"></div>
        </div>
        <img src={imgCycle} alt="" className="cycle cycle-2" />
      </div>
      <OverViewStyled>
        <h1>
          {t('home.affiliateDashboard')} <span>{t('home.overview')}</span>
        </h1>
        <div className="overview">
          <OverViewCardStyled>
            <img src={moneyIcon} alt="money" />
            <div>
              {formatNumberWithCommas(
                // overview?.totalEarn ? overview?.totalEarn : 0,
                10000,
              )}
              <span>USDT</span>
            </div>
            <p>{t('home.earnedTotal')}</p>
          </OverViewCardStyled>
          <OverViewCardStyled>
            <img src={walletIcon} alt="money" />
            <div>
              {formatNumberWithCommas(
                // overview?.avgMonth ? overview?.avgMonth : 0,
                400,
              )}
              <span>USDT</span>
            </div>
            <p>{t('home.averageEarnedPerMonth')}</p>
          </OverViewCardStyled>
          {/* <OverViewCardStyled>
            <img src={groupIcon} alt="money" />
            <div>
              {formatNumberWithCommas(
                overview?.totalAffiliate ? overview?.totalAffiliate : 0,
              )}
              <span>USDT</span>
            </div>
            <p>{t('home.totalAffiliates')}</p>
          </OverViewCardStyled> */}
          <OverViewCardStyled>
            <img src={groupIcon} alt="money" />
            <div>{formatNumberWithCommas(downloadCount)}</div>
            <p>{t('home.totalDownload')}</p>
          </OverViewCardStyled>
        </div>
      </OverViewStyled>
      <OverViewFAQ>
        <h1>
          {t('home.frequentlyAsked')} <span>{t('home.questions')}</span>
        </h1>
        <FAQ />
      </OverViewFAQ>
    </WrapperStyled>
  )
}

export default SectionOverView
