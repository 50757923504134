import { LangCode, changeLanguage } from '@/i18n'
import { parseCulture } from '@/utils'
import { getCookie } from '@/utils/cookie'
import {
  createContext,
  useState,
  useEffect,
  FC,
  PropsWithChildren,
} from 'react'

interface LangCheckContextType {
  langCookie?: string | undefined
}

const defaultValue: LangCheckContextType = {
  langCookie: undefined,
}

export const LangCheckContext =
  createContext<LangCheckContextType>(defaultValue)

export const LangCheckProvider: FC<PropsWithChildren> = ({ children }) => {
  const [langCookie, setLangCookie] = useState<string>('')

  useEffect(() => {
    const culture = getCookie('.AspNetCore.Culture')
    if (culture) {
      const cultureParsed =
        parseCulture(decodeURIComponent(culture) || '') || null
      if (cultureParsed) {
        changeLanguage(cultureParsed as LangCode)
        setLangCookie(cultureParsed as LangCode)
      }
    } else {
      changeLanguage('en')
      setLangCookie('en')
    }
  }, [])

  return (
    <>
      <LangCheckContext.Provider value={{ langCookie }}>
        {children}
      </LangCheckContext.Provider>
    </>
  )
}

export default LangCheckProvider
