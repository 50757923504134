import { createRoot } from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { RouterProvider } from 'react-router-dom'

import './index.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Provider } from 'react-redux'

import './i18n'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import store from './state'
import router from './routers'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const root = createRoot(document.getElementById('root') as HTMLElement)

declare module '@mui/material/styles' {
  interface Palette {
    greyPagination: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    greyPagination?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Pagination' {
  interface PaginationPropsColorOverrides {
    greyPagination: true
  }
}

declare global {
  interface Navigator {
    herond: {
      isHerond: () => Promise<boolean>
    }
    userAgentData: {
      getHighEntropyValues: (arr: string[]) => Promise<{
        architecture: string
      }>
    }
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    greyPagination: {
      main: '#7A818C',
    },
  },
})

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>,
)
